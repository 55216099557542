import { makeStyles } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import orange from '@material-ui/core/colors/orange';
import brown from '@material-ui/core/colors/brown';
import red from '@material-ui/core/colors/red';

const receiptAndColours = [
  ['ENROUTE', lightBlue[400], true],
  ['DELIVERED', green[600], true],
  ['EXPIRED', orange[700]],
  ['UNDELIVERABLE', red[800], true],
  ['DELETED', brown[500]],
];

const style = {};
const colours = {};
const receipts = [];

receiptAndColours.forEach(item => {
  const [status, colour] = item;

  style[status] = { colour };
  colours[status] = colour;
  receipts.push(status);
});

const styles = makeStyles(() => style);

export {
  receiptAndColours,
  receipts,
  colours,
  styles,
};
