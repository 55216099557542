import React, { useState, useEffect, useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import ApiHOC from '../../../lib/ApiHOC';
import { FlashContext } from '../../../context/FlashContext';
import TableWrapper from '../../../components/TableWrapper';
import ContainerWrapper from '../../../components/ContainerWrapper';

import Header from './Header';
import { Typography, Grid } from '@material-ui/core';
import PrettifyAddress from '../../../components/PrettifyAddress';

const Inbound = (props) => {
  const [pushFlashState] = useContext(FlashContext);
  const [reportState, setReportState] = useState({});
  const [inputState, setInputState] = useState({});
  const [processState, setProcessState] = useState({});

  useEffect(() => {
    document.title = 'Reports - Inbound';
  });

  const handlePagination = async page => {
    await handleSubmit({ ...inputState, page });
  }

  const handleSubmit = async newInputState => {
    setInputState({
      ...newInputState,
    });

    setProcessState({
      ...processState,
      request: true,
    });

    setReportState({
      result: false,
    });

    let uri;
    let query = {};

    switch (newInputState.search) {
      case 'messageId':
        uri = `/report/inbound/messageId/${newInputState.messageId}`;
        query = {};
        break;
      case 'addr':
        uri = '/report/inbound/sourceAddr';
        query = {
          startDate: newInputState.startDate,
          finishDate: newInputState.finishDate,
          sourceAddr: newInputState.addr,
        };
        break;
      case 'summary':
        uri = '/report/inbound/summary';
        query = {
          startDate: newInputState.startDate,
          finishDate: newInputState.finishDate,
        };
        break;
      default:
        uri = '/report/inbound/date';
        query = {
          startDate: newInputState.startDate,
          finishDate: newInputState.finishDate,
        };
        break;
    }

    await props.handleRequest(null, uri, 'get', query)
      .then(resp => {
        setReportState({
          result: true,
          ...resp,
        });
      })
      .catch(() => {
        pushFlashState(
          'Unable to fetch reporting data',
          'error',
        );
      });

    setProcessState({
      ...processState,
      request: false,
    });
  }

  const ready = props.data ? true : false;
  const dateFormat = 'D MMM YYYY, HH:mm:ss';

  return (
    <>
      <Header
        onSubmit={handleSubmit}
        direction="Inbound"
        addr="Source Number"
      />
        {(processState.request || reportState.result) && (
          <>
            <ContainerWrapper
              ready={true}
              marginTop={false}
            >
              <>
                {processState.request && <LinearProgress />}
                {reportState && reportState.items && (
                  <TableWrapper
                    headers={[
                      'Message ID',
                      'Source Address',
                      'Destination Address',
                      'Message',
                      'Created',
                    ]}
                    ready={ready}
                    emptyText="No messages found, try expanding your search."
                    ellipsis={{
                      0: 8,
                      3: 60,
                    }}
                    dateFormat={dateFormat}
                    rows={ready ? reportState.items.map(item => {
                      if (!item) {
                        return [];
                      }

                      return [
                        item.id,
                        <PrettifyAddress>{item.sourceAddr}</PrettifyAddress>,
                        <PrettifyAddress>{item.destAddr}</PrettifyAddress>,
                        item.message,
                        item.created
                      ]
                    }): []}
                    hasNext={ready && reportState.hasNext}
                    pageSize={ready ? reportState.pageSize : 0}
                    pageNumber={ready ? reportState.pageNumber : 0}
                    pagination={handlePagination}
                    container={false}
                />
                )}
                {reportState && reportState.messages >= 0 && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          Messages Received
                        </Typography>
                        <Typography variant="body1">
                          {new Intl.NumberFormat({ style: 'decimal', maximumFractionDigits: 0 }).format(reportState.messages)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          Message Parts
                        </Typography>
                        <Typography variant="body1">
                          {new Intl.NumberFormat({ style: 'decimal', maximumFractionDigits: 0 }).format(reportState.parts)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </>
                )}
            </>
          </ContainerWrapper>
        </>
      )}
    </>
  );
};

const InboundFetch = ApiHOC(
  Inbound,
);

export default InboundFetch;
