import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip';
import FileCopy from '@material-ui/icons/FileCopy';
import Autorenew from '@material-ui/icons/Autorenew';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FlashContext } from '../../context/FlashContext';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3)
  },
  formControl: {
    width: '100%',
  },
}));

const Generate = props => {
  const classes = useStyles();
  const [pushFlashState] = useContext(FlashContext);

  const handleClickCopy = () => {
    pushFlashState(`${props.name} copied to clipboard`, 'success');
  };

  const handleMouseDown = event => {
    event.preventDefault();
  };

  const id = props.name.toLowerCase().replace(/[^0-9a-z]/gi, '-');

  return (
    <div className={classes.root}>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor={id}>{props.name}</InputLabel>
        <OutlinedInput
          id={id}
          type="text"
          value={props.value}
          disabled={true}
          endAdornment={
            <>
              <CopyToClipboard text={props.value} onCopy={handleClickCopy}>
                <InputAdornment position="end">
                  <Tooltip title="Copy to clipboard">
                    <IconButton
                      onClick={handleClickCopy}
                      onMouseDown={handleMouseDown}
                      readOnly={true}
                      edge="end"
                    >
                      <FileCopy />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              </CopyToClipboard>
              <InputAdornment position="end">
                <Tooltip title="Generate new key">
                  <IconButton
                    onClick={props.generate}
                    readOnly={true}
                    edge="end"
                  >
                    <Autorenew />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            </>
          }
          labelWidth={props.name.length * 10}
        />
      </FormControl>
    </div>
  );
}

export default Generate;
