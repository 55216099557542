import React, { useState, useContext, useEffect } from 'react';
import { validateToken, validateRefreshToken } from '../lib/Token';
import { FlashContext } from './FlashContext';
import { debug } from '../lib/Debug';

const UserContext = React.createContext([{
  ready: false,
  accessToken: null,
  refreshToken: null,
  uuid: null,
  verified: false,
  emailVerified: false,
}, () => {}]);

const UserContextProvider = (props) => {
  const [pushFlashState] = useContext(FlashContext);
  const [state, setState] = useState({
    ready: false,
    accessToken: localStorage.getItem('accessToken'),
    refreshToken: localStorage.getItem('refreshToken'),
    uuid: localStorage.getItem('uuid'),
    verified: false,
    opts: {
      firstName: localStorage.getItem('opt_firstName'),
      payment: localStorage.getItem('opt_payment'),
      balance: localStorage.getItem('opt_balance'),
      emailVerified: localStorage.getItem('opt_emailVerified'),
      admin: localStorage.getItem('opt_admin'),
    }
  });

  useEffect(() => {
    if (state && state.accessToken && !state.verified) {
      const logoutState = () => {
        setState({
          ...state,
          ready: true,
          accessToken: null,
          refreshToken: null,
          uuid: null,
          verified: false,
        });

        localStorage.clear();

        pushFlashState('You\'ve been logged out due to inactivity', 'error');
      };

      const doRefreshToken = async () => {
        return validateRefreshToken(state.uuid, state.refreshToken)
          .catch(err => {
            debug('UserContextProvider-doRefreshToken', 'error', err.message);

            logoutState();
          });
      }

      const doValidateToken = async () => {
        const newState = { ...state, ready: true };
        const resp = await validateToken(state.uuid, state.accessToken)
          .catch(async err => {
            if (err.name === 'TokenExpiredError') { // Try Refresh Token
              return doRefreshToken();
            }

            debug('UserContextProvider-doValidateToken', 'error', err.message);
          });

        if (resp) {
          if (resp.accessToken) {
            setState({
              ...newState,
              ...resp,
              verified: true,
            });

            setSessionState(resp);
          } else {
            setState({
              ...newState,
              ...resp,
              verified: true,
            });
          }
        } else {
          logoutState();
        }
      };

      doValidateToken();
    } else if (!state.ready) {
      setState({ ...state, ready: true })
    }
  }, [state, setState, pushFlashState]);

  const handlePushFlashState = (message, variant) => pushFlashState(message, variant);

  const setSessionState = (newState) => {
    if (newState.accessToken) {
      localStorage.setItem('accessToken', newState.accessToken);
    }
    if (newState.refreshToken) {
      localStorage.setItem('refreshToken', newState.refreshToken);
    }
    if (newState.uuid) {
      localStorage.setItem('uuid', newState.uuid);
    }
    if (newState.opts) {
      Object.keys(newState.opts)
        .forEach(opt => {
          localStorage.setItem(`opt_${opt}`, newState.opts[opt]);
        });
    }

    setState(newState);
  }

  return (
    <UserContext.Provider value={[state, setSessionState, handlePushFlashState]}>
      {props.children}
    </UserContext.Provider>
  );
};

export { UserContext, UserContextProvider };
