import React from 'react';
import PropTypes from 'prop-types';

const NumberFormat = (props) => {
  let numberFormat = new Intl.NumberFormat('en-AU', { style: props.style, currency: 'AUD' });

  if (props.type === 'decimal') {
    numberFormat = new Intl.NumberFormat({ style: props.style, maximumFractionDigits: props.maximumFractionDigits });
  }

  return (
    <>
      {numberFormat.format(props.children > 0 ? props.children : 0)}
    </>
  )
}

export default NumberFormat;

NumberFormat.propTypes = {
  type: PropTypes.string,
  children: PropTypes.number.isRequired,
  maximumFractionDigits: PropTypes.number,
};

NumberFormat.defaultProps = {
  type: 'currency',
  maximumFractionDigits: 0,
};
