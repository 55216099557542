import { LinearProgress } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import { UserContext } from '../../context/UserContext';
import ApiHOC from '../../lib/ApiHOC';
import React, { useState, useEffect, useContext } from 'react';
import { ROUTER_INDEX } from '../../routes/Constants';

const VerifyEmailAddress = (props) => {
  const [userState, setUserState, pushFlashState] = useContext(UserContext);
  const [state, setState] = useState({
    processing: false,
    ready: false,
  })

  useEffect(() => {
    document.title = 'Verify Email Address';

    const fetchAsync = async () => {
      const body = {
        usernameHash: props.match.params.usernameHash,
        token: props.match.params.token,
      };

      await props.handleRequest(null, '/auth/verify-email', 'post', {}, body)
        .then(() => {
          pushFlashState(
            'Email address verified',
            'success',
          );

          setUserState({
            ...userState,
            opts: {
              ...userState.opts,
              emailVerified: true,
            },
          });
        })
        .catch(err => {
          pushFlashState(
            'Expired or invalid link, try again',
            'error',
          );
        });

      setState({
        processing: true,
        ready: true,
      });
    };

    if (!state.processing) {
      setState({
        processing: true,
      });

      fetchAsync();
    }
  }, [state, setState, props, userState, setUserState, pushFlashState]);

  const ready = (state && state.ready) ? state.ready : false;

  if (ready) {
    return <Redirect to={ROUTER_INDEX} />;
  }

  return (
    <LinearProgress />
  );
}

const VerifyEmailAddressFetch = ApiHOC(
  VerifyEmailAddress,
);

export default VerifyEmailAddressFetch;
