import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ApiHOC from '../../lib/ApiHOC';
import { UserContext } from '../../context/UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import { ROUTER_INDEX } from '../../routes/Constants';

const Logout = (props) => {
  const history = useHistory();
  const [localState, setLocalState] = useState({});
  const [state, setState, pushFlashState] = useContext(UserContext);

  useEffect(() => {
    document.title = 'Logout';

    if (state.ready && !localState.ready) {
      setLocalState({
        ready: true,
      });

      const body = {
        refreshToken: state.refreshToken,
      };

      props.handleRequest(null, '/auth/logout', 'post', {}, body)
        .then(() => {
          setState({
            ready: true,
            verified: false,
            accessToken: null,
            refreshToken: null,
            uuid: null,
          });

          localStorage.clear();

          pushFlashState(
            'You\'ve been logged out',
            'success',
          );

          history.replace(ROUTER_INDEX);
        })
        .catch(() => {
          history.replace(ROUTER_INDEX);
        });
    }
  }, [localState, setLocalState, state, props, history, pushFlashState, setState]);

  return (
    <LinearProgress />
  );
}


const LogoutFetch = ApiHOC(
  Logout,
);

export default LogoutFetch;
