import React from 'react';
import PropTypes from 'prop-types';

const Capitalise = ({children}) => {
  const newS = children.toLowerCase();

  return <>{newS.charAt(0).toUpperCase()}{newS.slice(1)}</>;
};

export {
  Capitalise,
}

Capitalise.propTypes = {
  children: PropTypes.string.isRequired,
};
