import React, { useContext, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import { UserContext } from '../context/UserContext';
import CircularProgress from '@material-ui/core/CircularProgress';
import ApiHOC from '../lib/ApiHOC';

const useStyles = makeStyles(() => ({
  hover: {
    cursor: 'pointer',
  }
}));

const VerifyEmailAddressHeader = (props) => {
  const [pageState, pageSetState] = useState({
    processing: false,
    hover: false,
  });
  const classes = useStyles();
  const context = useContext(UserContext);
  const state = context[0];
  const pushFlashState = context[2];

  const handleClick = async (e) => {
    e.preventDefault();

    pageSetState({
      ...pageState,
      processing: true,
    });

    await props.handleRequest(null, '/user/verification', 'post')
      .then(() => {
        pushFlashState(
          'Verification email sent',
          'success',
        );
      })
      .catch(() => {
        pushFlashState(
          'Unable to send verification email, please try again',
          'error',
        );
      });

    pageSetState({
      processing: false,
    });
  }

  const mouseEnter = () => {
    pageSetState({
      ...pageState,
      hover: true,
    });
  }

  const mouseLeave = () => {
    pageSetState({
      ...pageState,
      hover: false,
    });
  }

  if (
    state.ready &&
    state.verified &&
    state.opts &&
    (
      state.opts.emailVerified === false ||
      state.opts.emailVerified === 'false'
    )
  ) {
    return (
      <Alert
        severity="warning"
        className={classes.hover}
        onMouseEnter={mouseEnter}
        onMouseLeave={mouseLeave}
        onClick={handleClick}
      >
        {pageState.processing && (
          <CircularProgress style={{width: '15px', height: '15px'}} />
        )}
        {!pageState.processing && pageState.hover && (
          <>Resend verification email.</>
        )}
        {!pageState.processing && !pageState.hover && (
          <>Please verify your email address in order to use all our features.</>
        )}
      </Alert>
    )
  }

  return <></>;
};

const VerifyEmailAddressHeaderFetch = ApiHOC(
  VerifyEmailAddressHeader,
);

export default VerifyEmailAddressHeaderFetch;
