import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import FormControl from '@material-ui/core/FormControl';
import FileCopy from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { FlashContext } from '../../../context/FlashContext';
import { Tooltip } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing(3)
  },
  formControl: {
    width: '100%',
  },
}));

const Key = props => {
  const classes = useStyles();
  const [pushFlashState] = useContext(FlashContext);

  const handleClickCopy = () => {
    pushFlashState(`${props.name} copied to clipboard`, 'success');
  };

  const handleMouseDown = event => {
    event.preventDefault();
  };

  const id = props.name.toLowerCase().replace(/[^0-9a-z]/gi, '-')

  return (
    <div className={classes.root}>
      <CopyToClipboard text={props.value || ''} onCopy={handleClickCopy}>
        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel htmlFor={id}>{props.name}</InputLabel>
          <OutlinedInput
            id={id}
            type="text"
            defaultValue={props.value || ''}
            endAdornment={
              <InputAdornment position="end">
                <Tooltip title="Copy to clipboard">
                  <IconButton
                    aria-label="copy value"
                    onClick={handleClickCopy}
                    onMouseDown={handleMouseDown}
                    readOnly={true}
                    edge="end"
                  >
                    <FileCopy />
                  </IconButton>
                </Tooltip>
              </InputAdornment>
            }
            labelWidth={props.name.length * 10}
          />
        </FormControl>
      </CopyToClipboard>
    </div>
  );
}

export default Key;
