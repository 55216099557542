import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import MomentUtils from '@date-io/moment';
import {
  MuiPickersUtilsProvider,
  DateTimePicker,
} from '@material-ui/pickers';

const InputDate = (props) => {
  const [pageState, setPageState] = useState({});

  useEffect(() => {
    if (!pageState || !pageState.value) {
      setPageState({
        ...pageState,
        value: props.value,
      })
    }
  }, [pageState, setPageState, props]);

  const handleChange = value => {
    setPageState({
      ...pageState,
      value,
    });

    props.onChange(props.id, value);
  }

  if (Date.now() > 0) {
    return (
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <DateTimePicker
          label={props.label}
          inputVariant="outlined"
          value={pageState.value}
          onChange={handleChange}
          fullWidth
          format="DD-MMM-YYYY, HH:mm"
        />
      </MuiPickersUtilsProvider>
    )
  }
};

export default InputDate;

InputDate.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
