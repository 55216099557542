import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

const useStyles = makeStyles(theme => ({
  submit: {
    margin: theme.spacing(3, 0, 0),
  },
}));

const SubmitButton = (props) => {
  const classes = useStyles();

  return (
    <Button
      type="submit"
      variant="contained"
      color="primary"
      className={classes.submit}
      disabled={props.disabled}
    >
      {props.children}
    </Button>
  );
}

export default SubmitButton;
