import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import PaymentIndex from './Index';
import CardRoutes from './CreditCard/Routes';
import { ROUTER_PAYMENT_INDEX, ROUTER_PAYMENT_CARD_INDEX, ROUTER_INDEX, ROUTER_PAYMENT_CREDIT_INDEX, ROUTER_PAYMENT_HISTORY_INDEX } from '../../../routes/Constants';
import PaymentCredits from './Credit';
import PaymentHistory from './History';

const PaymentRoutes = () => {
  const [state] = useContext(UserContext);

  if (!state || !state.ready || !state.verified) {
    return <LinearProgress />;
  }

  return (
    <Switch>
      <Route exact path={ROUTER_PAYMENT_INDEX} component={PaymentIndex} />
      <Route exact path={ROUTER_PAYMENT_CREDIT_INDEX} component={PaymentCredits} />
      <Route exact path={ROUTER_PAYMENT_HISTORY_INDEX} component={PaymentHistory} />
      <Route path={ROUTER_PAYMENT_CARD_INDEX} component={CardRoutes} />
      <Route>
        <Redirect to={ROUTER_INDEX} />
      </Route>
    </Switch>
  );
};

export default PaymentRoutes;
