import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';

const useStyles = makeStyles(() => ({
  formControl: {
    width: '100%',
  },
}));

const InputSelect = ({ id, handleChange, items, label, value, disabled }) => {
  const classes = useStyles();
  const inputLabel = useRef(null);

  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    setLabelWidth(inputLabel.current.offsetWidth);
  }, []);

  const handleSelectChange = event => {
    handleChange(event.target.value);
  }

  return (
    <FormControl variant="outlined" className={classes.formControl}>
      <InputLabel ref={inputLabel} id={`${id}-label`}>
        {label}
      </InputLabel>
      <Select
        fullWidth
        labelId={`${id}-label`}
        id={id}
        value={value}
        onChange={handleSelectChange}
        labelWidth={labelWidth}
        required={true}
        disabled={disabled}
      >
        {items.map(item => (
          <MenuItem value={item.value} key={item.value}>{item.label}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default InputSelect;

InputSelect.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ]),
  items: PropTypes.arrayOf(
    PropTypes.exact({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool,
      ]),
      label: PropTypes.string,
    })
  ),
  handleChange: PropTypes.func.isRequired,
};

InputSelect.defaultProps = {
  value: null,
  disabled: false,
};
