import React, { useEffect, useState, useContext } from 'react';
import ApiHOC from '../../../lib/ApiHOC';
import ContainerWrapper from '../../../components/ContainerWrapper';
import TableWrapper from '../../../components/TableWrapper';
import { FlashContext } from '../../../context/FlashContext';
import { ROUTER_PAYMENT_INDEX, ROUTER_INDEX, ROUTER_PAYMENT_CREDIT_INDEX, ROUTER_PAYMENT_HISTORY_INDEX } from '../../../routes/Constants';
import DialogWrapper from '../../../components/DialogWrapper';
import { Typography, Divider, makeStyles, Grid } from '@material-ui/core';
import Moment from 'react-moment';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(3, 1, 1),
  },
}));

const PaymentHistory = (props) => {
  const classes = useStyles();
  const [pushFlashState] = useContext(FlashContext);
  const [modalState, setModalState] = useState({
    open: false,
  });
  const [state, setState] = useState({
    ready: false,
  });

  useEffect(() => {
    document.title = 'Payment History';

    if (!state.ready && props.data) {
      setState({
        ...state,
        ...props.data,
        ready: true,
      });
    }
  }, [props, state, setState, pushFlashState]);

  const { ready } = state;

  const handlePagination = async pageNumber => {
    const resp = await props.handleRequest('data', '/payment/history', 'get', { page: pageNumber });

    await setState({
      ...state,
      ...resp,
    });
  }

  const handlePrimaryClick = item => {
    const newModalState = state.items[item.record];

    toggleModal(true, newModalState);
  }

  const toggleModal = (open, newModalState = {}) => {
    if (open && modalState.processing) { // Don't close processing payment
      return;
    }

    setModalState({
      ...newModalState,
      open,
    });
  }

  const cancelPayment = async paymentId => {
    setModalState({
      ...modalState,
      processing: true,
    });

    const open = await props.handleRequest('data', `/payment/cancel/${paymentId}`, 'patch')
      .then(async () => {
        pushFlashState('Payment cancelled', 'success');

        await handlePagination(state.pageNumber ? 0 : state.pageNumber);

        return false;
      })
      .catch(() => {
        pushFlashState('Unable to cancel payment', 'error');

        return true;
      });

    setModalState({
      ...modalState,
      open,
      processing: false,
    });
  }

  return (
    <ContainerWrapper
      ready={state.ready}
      title="Payment History"
      button={{ text: 'Purchase Credits', link: ROUTER_PAYMENT_CREDIT_INDEX }}
      breadcrumbs={[
        [
          'Dashboard',
          ROUTER_INDEX,
        ],
        [
          'Payments',
          ROUTER_PAYMENT_INDEX,
        ],
        [
          'History',
          ROUTER_PAYMENT_HISTORY_INDEX,
        ]
      ]}
    >
      <TableWrapper
        headers={['Payment ID', 'Amount', 'Status', 'Created']}
        ready={ready}
        ellipsis={{
          0: 8,
        }}
        rows={ready ? state.items.map(item => [
          item.id,
          new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(item.amount),
          item.status,
          item.created,
        ]) : []}
        hasNext={ready && state.hasNext}
        pageSize={ready ? state.pageSize : 0}
        pageNumber={ready ? state.pageNumber : 0}
        pagination={handlePagination}
        primaryAction={{
          handleClick: handlePrimaryClick,
          title: 'payment id',
          replacements: [{
            key: 'paymentId',
            value: 0,
          }]
        }}
      />
      <DialogWrapper
        open={modalState.open}
        title="Payment Item"
        handleToggle={() => toggleModal(false)}
        close="Close"
        primaryAction={modalState.status === 'PENDING' ? {
          label: 'Cancel Payment',
          class: 'secondary',
          handleClick: () => cancelPayment(modalState.id),
          processing: modalState.processing ? true : false,
        } : undefined}
      >
        <>
        {modalState && modalState.id && (
          <>
            <Typography variant="subtitle2">
              Payment ID
            </Typography>
            <Typography variant="body1">
              {modalState.id}
            </Typography>

            <Divider light variant="middle" className={classes.divider} />

            <Typography variant="subtitle2">
              Date Created
            </Typography>
            <Typography variant="body1">
              <Moment format="DD/MM/YYYY">
                {modalState.created}
              </Moment>
            </Typography>

            <Divider light variant="middle" className={classes.divider} />

            <Grid container spacing={3}>
              <Grid item xs={6}>
                <>
                <Typography variant="subtitle2">
                  Amount
                </Typography>
                <Typography variant="body1">
                  {new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(modalState.amount)}
                </Typography>
                </>
              </Grid>
              <Grid item xs={6}>
                <>
                <Typography variant="subtitle2">
                  Status
                </Typography>
                <Typography variant="body1">
                  {modalState.status}
                </Typography>
                </>
              </Grid>
            </Grid>

            {modalState.items && Object.keys(modalState.items).length > 0 && (
              <>
                <Divider light variant="middle" className={classes.divider} />
                <Typography variant="subtitle2">
                  Items
                </Typography>
                <Grid container spacing={3}>
                  {Object.keys(modalState.items).map(key => (
                    <Grid item xs={6} key={key}>
                      <>
                        <Typography variant="body1">
                          {key}
                        </Typography>
                        <Typography variant="body2">
                          {new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(modalState.items[key].amount)}
                        </Typography>
                      </>
                    </Grid>
                  ))}
                </Grid>
              </>
            )}
          </>
        )}
        </>
      </DialogWrapper>
    </ContainerWrapper>
  );
};

const PaymentHistoryFetch = ApiHOC(
  PaymentHistory,
  { uri: '/payment/history'  }
);

export default PaymentHistoryFetch;
