import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';

import { UserContext } from '../../context/UserContext';
import Input from '../../components/forms/Input';
import SkeletonInput from '../../partials/SkeletonInput';
import AuthForm from '../../components/AuthForm';
import ApiHOC from '../../lib/ApiHOC';
import { debug } from '../../lib/Debug';
import { ROUTER_LOGIN } from '../../routes/Constants';

const ResetPassword = (props) => {
  const history = useHistory();
  const pushFlashState = useContext(UserContext)[2];
  const [form, setForm] = useState({});
  const [process, setProcess] = useState({
    form: false,
    page: false,
  });
  const [error, setError] = useState({});
  const [state, setState] = useState({});

  useEffect(() => {
    document.title = 'Reset Password';

    const fetchAsync = async () => {
      const body = {
        usernameHash: props.match.params.usernameHash,
        token: props.match.params.token,
      };

      await props.handleRequest(null, '/auth/reset-password', 'post', {}, body)
        .then(() => {
          setState({
            processing: true,
            ready: true,
          });
        })
        .catch(err => {
          pushFlashState(
            'Expired or invalid forgot password link, try again',
            'error',
          );

          return history.push(ROUTER_LOGIN);
        });
    };

    if (!state.processing) {
      setState({
        processing: true,
      });

      fetchAsync();
    }
  }, [state, setState, props, pushFlashState, history]);

  const handleChange = e => {
    const { id, value } = e.target;

    setForm({ ...form, [id]: value });
    setError({ ...error, [id]: false });
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    setError({});
    setProcess({ ...process, form: true });

    const newError = {};

    if (!form.password || form.password.length < 5) {
      newError.password = true;
    }

    if (!form.passwordConfirm || form.password !== form.passwordConfirm) {
      newError.passwordConfirm = true;
    }

    setError(newError);

    if (Object.keys(newError).length === 0) {
      const body = {
        password: form.password,
        usernameHash: props.match.params.usernameHash,
        token: props.match.params.token,
      };

      try {
        await props.handleRequest(null, '/auth/reset-password', 'patch', {}, body);

        pushFlashState(
          'Password reset, you can now login',
          'success',
        );

        history.replace(ROUTER_LOGIN);
      } catch (err) {
        debug('ResetPassword-handleSubmit', 'error', err.message);

        pushFlashState(
          'Unable to reset password, try again',
          'error',
        );
      }
    }

    setProcess({ ...process, form: false });
  }

  const ready = (state && state.ready) ? state.ready : false;

  return (
    <AuthForm
      title="Reset Password"
      disabled={process.form}
      onSubmit={handleSubmit}
      ready={ready}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {
            ready ? <Input
                id="password"
                label="Password"
                type="password"
                onChange={handleChange}
                required={true}
                disabled={process.password}
                error={!!error.password}
                helperText="Password is a bit weak, try making it a bit longer"
              /> : <SkeletonInput />
          }
        </Grid>
        <Grid item xs={12}>
          {
            ready ? <Input
              id="passwordConfirm"
              label="Confirm Password"
              type="password"
              onChange={handleChange}
              required={true}
              disabled={process.passwordConfirm}
              error={!!error.passwordConfirm}
              helperText="Passwords do not match"
            /> : <SkeletonInput />
          }
        </Grid>
      </Grid>
    </AuthForm>
  );
}

const ResetPasswordFetch = ApiHOC(
  ResetPassword,
);

export default ResetPasswordFetch;
