import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
}));

const Form = (props) => {
  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={props.onSubmit} noValidate>
      {props.children}
    </form>
  );
};

export default Form;
