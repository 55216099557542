import React, { useEffect, useState } from 'react';
import ApiHOC from '../../lib/ApiHOC';
import { ROUTER_INDEX, ROUTER_WEBHOOK_INDEX } from '../../routes/Constants';
import FormWrapper from '../../components/forms/FormWrapper';
import Grid from '@material-ui/core/Grid';
import SkeletonInput from '../../partials/SkeletonInput';
import Input from '../../components/forms/Input';
import { FlashContext } from '../../context/FlashContext';
import { useContext } from 'react';

import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import Generate from '../../components/forms/Generate';

const Webhook = (props) => {
  const [state, setState] = useState({
    ready: false,
  });
  const [processState, setProcessState] = useState({});
  const [inputState, setInputState] = useState({});
  const [errorState, setErrorState] = useState({});
  const [pushFlashState] = useContext(FlashContext);
  const dlrStatus = ['ENROUTE','DELIVERED','EXPIRED','DELETED','UNDELIVERABLE','OPTOUT'];

  useEffect(() => {
    document.title = 'Webhooks';

    if (!state.ready && props.data) {
      setState({
        ...state,
        ...props.data,
        ready: true,
      });

      const dlrs = {};

      dlrStatus.forEach(status => {
        dlrs[status] = false;
      });

      setInputState({
        ...props.data,
        dlrs: {
          ...dlrs,
          ...props.data.dlrs,
        },
      });
    }
  }, [props, state, setState, setInputState, dlrStatus]);

  const handleChange = e => {
    const { id, value } = e.target;

    setInputState({
      ...inputState,
      [id]: value,
    });

    setErrorState({
      ...errorState,
      [id]: null,
    });
  };

  const handleToggleChange = state => event => {
    const inputGroup = inputState.dlrs;
    const { checked } = event.target;

    if (inputGroup[state] !== checked) {
      setInputState({
        ...inputState,
        dlrs: {
          ...inputGroup,
          [state]: checked,
        },
      });
    }
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    setErrorState({});
    setProcessState({
      ...processState,
      form: true,
    });

    if (event) {
      event.preventDefault();
    }

    setProcessState({
      ...processState,
      form: true,
    });

    const newError = {};

    const httpRegex = /^http(s)?:\/\//;

    if (inputState.mo && !inputState.mo.match(httpRegex)) {
      newError.mo = true;
    }
    if (inputState.dlr && !inputState.dlr.match(httpRegex)) {
      newError.dlr = true;
    }

    setErrorState(newError);

    if (Object.keys(newError).length === 0) {
      const body = {
        mo: inputState.mo,
        dlr: inputState.dlr,
        dlrs: inputState.dlrs,
      };

      await props.handleRequest(null, '/webhook', 'patch', {}, body)
        .then(() => {
          pushFlashState(
            'Webhooks updated',
            'success',
          );
        })
        .catch(() => {
          pushFlashState(
            'Unable to update webhooks',
            'error',
          );
        });
    }

    setProcessState({
      ...processState,
      form: false,
    });
  };

  const generateKey = async () => {
    setProcessState({
      ...processState,
      form: true,
    });

    await props.handleRequest(null, '/webhook/secret', 'patch')
      .then(resp => {
        setInputState({
          ...inputState,
          secret: resp.secret,
        });

        pushFlashState(
          'Webhook client secret updated',
          'success',
        );
      })
      .catch(() => {
        pushFlashState(
          'Unable to update webhook client secret',
          'error',
        );
      });

    setProcessState({
      ...processState,
      form: false,
    });
  }

  const { ready } = state;

  return (
    <FormWrapper
      ready={ready}
      onSubmit={handleSubmit}
      action="Update"
      disabled={processState.form}
      title="Webooks"
      breadcrumbs={[
        [
          'Dashboard',
          ROUTER_INDEX,
        ],
        [
          'Webhook',
          ROUTER_WEBHOOK_INDEX,
        ],
      ]}
    >
      <Grid container spacing={3}>
        <Grid item xs={12}>
          {
            ready ? <Input
                id="dlr"
                label="Delivery Receipt URL"
                value={inputState.dlr}
                onChange={handleChange}
                disabled={processState.form}
                error={!!errorState.dlr}
                helperText="Must enter a valid URL"
              /> :
              <SkeletonInput />
          }
        </Grid>
        <Grid item xs={12}>
        {
          ready ? <FormControl component="fieldset">
            <FormLabel component="legend">Delivery Receipts</FormLabel>
            <FormGroup>
            {dlrStatus
              .map(item => (
                <FormControlLabel
                  key={item}
                  control={
                    <Switch
                      checked={inputState.dlrs[item] && inputState.dlrs[item] === true}
                      onChange={handleToggleChange(item)}
                      disabled={processState.form}
                      color="primary"
                    />
                  }
                  label={item}
                />
              ))}
            </FormGroup>
          </FormControl> :
          <SkeletonInput />
        }
        </Grid>
        <Grid item xs={12}>
          {
            ready ? <Input
                id="mo"
                label="Mobile Originated (Replies) URL"
                value={inputState.mo}
                onChange={handleChange}
                disabled={processState.form}
                error={!!errorState.mo}
                helperText="Must enter a valid URL"
              /> :
              <SkeletonInput />
          }
        </Grid>
        <Grid item xs={12}>
          {
            ready ? <Generate
              name="Client secret"
              value={inputState.secret}
              generate={generateKey}
              disabled={true}
            /> :
            <SkeletonInput />
          }
        </Grid>
      </Grid>
    </FormWrapper>
  );
};

const WebhookFetch = ApiHOC(
  Webhook,
  { uri: '/webhook'  }
);

export default WebhookFetch;
