import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from '../../components/NumberFormat';

const NumberFormatWrapper = ({ children }) => <NumberFormat type="decimal" maximumFractionDigits={0}>{children}</NumberFormat>

NumberFormatWrapper.propTypes = {
  children: PropTypes.number.isRequired,
};

export default NumberFormatWrapper;
