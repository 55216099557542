import React from 'react';
import { ROUTER_RESTRICTED_PAYMENT_INDEX } from '../../routes/Constants';
import { Redirect } from 'react-router-dom';

const Index = () => {
  return (
    <Redirect to={ROUTER_RESTRICTED_PAYMENT_INDEX} />
  );
};

export default Index;
