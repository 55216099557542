import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import LinkWrapper from '../lib/LinkWrapper';
import { usePaper } from '../helpers/Classes';
import { UserContext } from '../context/UserContext';
import Title from '../partials/Title';
import { Breadcrumbs, Typography, Link } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  appBarSpacer: {
    padding: theme.spacing(1),
  },
  containerBottom: {
    paddingBottom: theme.spacing(4),
  },
  containerTop: {
    paddingTop: theme.spacing(4),
  },
  breadcrumb: {
    marginBottom: theme.spacing(4),
  },
  paper: {
    paddingTop: theme.spacing(4),
  },
  rightAlign: {
    textAlign: 'right'
  }
}));

const ContainerWrapper = (props) => {
  const classes = useStyles();
  const paperClasses = usePaper();
  const [state] = useContext(UserContext);

  if (!state.ready) {
    return <LinearProgress />
  }

  const containerPadding = [classes.containerBottom];

  if (props.marginTop) {
    containerPadding.push(classes.containerTop);
  }

  return (
    <main className={classes.content}>
      <Container maxWidth="lg" className={containerPadding.join(' ')}>
      {props.breadcrumbs && props.breadcrumbs.length > 0 && (
        <Breadcrumbs className={classes.breadcrumb}>
          {props.breadcrumbs.map((breadcrumb, b) => {
            if (b+1 === props.breadcrumbs.length) {
              return <Typography color="textPrimary" key={b}>{breadcrumb[0]}</Typography>;
            } else {
              return <Link component={LinkWrapper} to={breadcrumb[1]} color="inherit" key={b}>{breadcrumb[0]}</Link>;
            }
          })}
        </Breadcrumbs>
      )}
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Paper className={clsx(classes.paper,paperClasses.paper)}>
              <Grid container spacing={3}>
                {props.title && (
                  <Grid item xs={12} sm={6}>
                    <Title>{props.title}</Title>
                    {props.subtitle  && <Title variant="subtitle1">{props.subtitle}</Title>}
                  </Grid>
                )}
                {props.button && (
                  <Grid item xs={12} sm={6} className={classes.rightAlign}>
                    <Button
                      variant="contained"
                      color="primary"
                      to={props.button.link}
                      component={LinkWrapper}
                      disabled={!props.ready}
                    >
                      {props.button.text}
                    </Button>
                  </Grid>
                )}
              </Grid>
              { props.ready && (
              <>
                <div className={classes.appBarSpacer} />
                { props.children }
              </>
              )}
              { !props.ready && (
                <LinearProgress />
              )}
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </main>
  );
};

export default ContainerWrapper;

ContainerWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  ready: PropTypes.bool.isRequired,
  title: PropTypes.string,
  button: PropTypes.shape({
    text: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired,
  }),
  marginTop: PropTypes.bool,
};

ContainerWrapper.defaultProps = {
  title: null,
  button: null,
  marginTop: true,
};
