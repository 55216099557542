import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableFooter from '@material-ui/core/TableFooter';
import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Typography from '@material-ui/core/Typography';
import Toolbar from '@material-ui/core/Toolbar';
import LinearProgress from '@material-ui/core/LinearProgress';
import Moment from 'react-moment';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import LinkWrapper from '../lib/LinkWrapper';
import NoItems from '../components/NoItems';

// const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop) ;

const useStyles = makeStyles(theme => ({
  // table: {
  //   minWidth: 650,
  // },
  pageNumber: {
    paddingRight: theme.spacing(2),

  },
  pageRows: {
    paddingRight: theme.spacing(1),
  },
  root: {
    flexGrow: 1,
  },
}));

const TableWrapper = (props) => {
  const tableRef = useRef(null);

  if (!props.ready) {
    return <LinearProgress />;
  }

  if (props.ready && props.emptyText && props.rows.length === 0) {
    return <NoItems>{props.emptyText}</NoItems>;
  }

  if (!props.container) {
    return <TableInternal {...props} />
  }

  return (
    <TableContainer component={Paper} ref={tableRef}>
      <TableInternal {...props} />
    </TableContainer>
  );
}

export default TableWrapper;

TableWrapper.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.string).isRequired,
  rows: PropTypes.arrayOf(PropTypes.array).isRequired,
  pagination: PropTypes.func.isRequired,
  emptyText: PropTypes.string,
  primaryAction: PropTypes.shape({
    url: PropTypes.string,
    handleClick: PropTypes.func,
    title: PropTypes.string,
    replacements: PropTypes.arrayOf(PropTypes.exact({
      key: PropTypes.string,
      value: PropTypes.number,
    })),
  }),
  hasNext: PropTypes.bool.isRequired,
  pageNumber: PropTypes.number.isRequired,
  ready: PropTypes.bool.isRequired,
  ellipsis: PropTypes.object,
  dateFormat: PropTypes.string,
};

TableWrapper.defaultProps = {
  hasNext: false,
  primaryAction: null,
  emptyText: null,
  ellipsis: {},
  dateFormat: 'D MMM YYYY',
};

const TableInternal = (props) => {
  const classes = useStyles();
  const [state, setState] = useState({
    processing: false,
  });

  const { headers, rows, hasNext, pageNumber, pagination, primaryAction } = props;

  const handlePagination = async (direction) => {
    setState({
      ...state,
      processing: true,
    });

    if (direction === 'next' && hasNext) {
      await pagination(pageNumber < 2 ? 2 : pageNumber + 1);
    } else if (direction === 'previous' && pageNumber > 1) {
      await pagination(pageNumber - 1);
    }

    setState({
      ...state,
      processing: false,
    });
  }

  const hasFooter = (
    hasNext || pageNumber > 1
  );

  return (
    <>
      {state.processing && (
        <LinearProgress />
      )}
      <Table className={classes.table}>
        <TableHead>
          <TableRow>
            {headers && headers.map((header, h) => (
              <TableCell key={`header_${h}`}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows && rows.map((row, r) => {
            const cells = row.map((cell, c) => {
              let content = cell;

              const title = headers[c].toLowerCase();

              if (title === 'created' || title === 'last used') {
                if (!cell) {
                  content = <em>Never</em>
                } else {
                  content = <Moment format={props.dateFormat}>{cell}</Moment>;
                }
              } else {
                const ellipsis = props.ellipsis[c];

                if (ellipsis) {
                  if (cell.length > (ellipsis + 5)) {
                    const TooltipContent = React.forwardRef((props, ref) => <div {...props} ref={ref}>{cell.substr(0, ellipsis)}...</div>);

                    content = <Tooltip disableFocusListener disableTouchListener title={cell}>
                      <TooltipContent />
                    </Tooltip>;
                  }
                }
              }

              if (primaryAction && primaryAction.title === title) {
                if (primaryAction.url) {
                  let to = primaryAction.url;

                  if (primaryAction.replacements && primaryAction.replacements.length) {
                    primaryAction.replacements.forEach(replacement => {
                      to = to.replace(replacement.key, row[replacement.value]);
                    });
                  }

                  content = (
                    <Link component={LinkWrapper} to={to} variant="body2">
                      {content}
                    </Link>
                  );
                } else if (primaryAction.handleClick) {
                  let handleClick = primaryAction.handleClick;
                  const args = {
                    record: r,
                  };

                  if (primaryAction.replacements && primaryAction.replacements.length) {
                    primaryAction.replacements.forEach(replacement => {
                      args[replacement.key] = row[replacement.value];
                    });
                  }

                  content = (
                    <Link onClick={() => handleClick(args)} variant="body2">
                      {content}
                    </Link>
                  )
                }
              }

              return <TableCell key={`cell_${c}`}>{content}</TableCell>
            });

            return (
              <TableRow key={r}>
                {cells}
              </TableRow>
            );
          })}
        </TableBody>
        {hasFooter && (
          <TableFooter>
            <TableRow>
              <TableCell colSpan={headers.length} variant="footer">
                <div className={classes.root}>
                  <Toolbar>
                    <div className={classes.root}>
                      <Typography variant="body2" component="span" className={classes.pageNumber}>Page {pageNumber}</Typography>
                    </div>
                    <IconButton
                      onClick={() => handlePagination('previous')}
                      disabled={pageNumber <= 1 || state.processing }
                      aria-label="previous page">
                      <KeyboardArrowLeft />
                    </IconButton>
                    <IconButton
                      onClick={() => handlePagination('next')}
                      disabled={!hasNext || state.processing}
                      aria-label="next page"
                    >
                      <KeyboardArrowRight />
                    </IconButton>
                  </Toolbar>
                </div>
              </TableCell>
            </TableRow>
          </TableFooter>
        )}
      </Table>
    </>
  );
}
