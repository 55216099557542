import React, { useState, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import LinearProgress from '@material-ui/core/LinearProgress';
import ApiHOC from '../../../lib/ApiHOC';
import { FlashContext } from '../../../context/FlashContext';
import TableWrapper from '../../../components/TableWrapper';
import ContainerWrapper from '../../../components/ContainerWrapper';

import Header from './Header';
import DialogWrapper from '../../../components/DialogWrapper';
import Moment from 'react-moment';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import PrettifyAddress from '../../../components/PrettifyAddress';
import green from '@material-ui/core/colors/green';
import lightBlue from '@material-ui/core/colors/lightBlue';
import orange from '@material-ui/core/colors/orange';
import brown from '@material-ui/core/colors/brown';
import red from '@material-ui/core/colors/red';

const useStyles = makeStyles(theme => ({
  divider: {
    margin: theme.spacing(3, 1, 1),
  },
  DELIVERED: {
    color: green[600],
  },
  EXPIRED: {
    color: orange[700],
  },
  ENROUTE: {
    color: lightBlue[400],
  },
  UNDELIVERABLE: {
    color: red[800],
  },
  DELETED: {
    color: brown[500],
  },
}));

const Outbound = (props) => {
  const classes = useStyles();

  const [pushFlashState] = useContext(FlashContext);
  const [reportState, setReportState] = useState({});
  const [inputState, setInputState] = useState({});
  const [processState, setProcessState] = useState({});
  const [modalState, setModalState] = useState({
    open: false,
  });

  useEffect(() => {
    document.title = 'Reports - Outbound';
  });

  const handlePagination = async page => {
    await handleSubmit({ ...inputState, page });
  }

  const handleSubmit = async newInputState => {
    setInputState({
      ...newInputState,
    });

    setProcessState({
      ...processState,
      request: true,
    });

    setReportState({
      result: false,
    });

    let uri;
    let query = {}

    switch (newInputState.search) {
      case 'messageId':
        uri = `/report/outbound/messageId/${newInputState.messageId}`;
        query = {};
        break;
      case 'addr':
        uri = '/report/outbound/destAddr';
        query = {
          startDate: newInputState.startDate,
          finishDate: newInputState.finishDate,
          destAddr: newInputState.addr,
          page: newInputState.page,
        };
        break;
      case 'summary':
        uri = '/report/outbound/summary';
        query = {
          startDate: newInputState.startDate,
          finishDate: newInputState.finishDate,
          page: newInputState.page,
        };
        break;
      default:
        uri = '/report/outbound/date';
        query = {
          startDate: newInputState.startDate,
          finishDate: newInputState.finishDate,
          page: newInputState.page,
        };
        break;
    }

    await props.handleRequest(null, uri, 'get', query)
      .then(resp => {
        setReportState({
          result: true,
          ...resp,
        });
      })
      .catch(() => {
        pushFlashState(
          'Unable to fetch reporting data',
          'error',
        );
      });

    setProcessState({
      ...processState,
      request: false,
    });
  }

  const handlePrimaryClick = item => {
    const newModalState = reportState.items[item.record];

    toggleModal(true, newModalState);
  }

  const toggleModal = (open, newModalState = {}) => {
    setModalState({
      ...newModalState,
      open,
    });
  }

  const ready = props.data ? true : false;
  const dateFormat = 'D MMM YYYY, HH:mm:ss';

  return (
    <>
      <Header
        onSubmit={handleSubmit}
        direction="Outbound"
        addr="Destination Number"
      />
        {(processState.request || reportState.result) && (
          <>
            <ContainerWrapper
              ready={true}
              marginTop={false}
            >
              <>
                {processState.request && <LinearProgress />}
                {reportState && reportState.items && (
                  <TableWrapper
                    headers={reportState && reportState.summary ? [
                      'Date',
                      'Messages',
                      'Parts',
                      'ENROUTE',
                      'DELIVERED',
                      'UNDELIVERABLE',
                      'DELETED',
                      'EXPIRED',
                      'OPTOUT',
                    ] : [
                      'Message ID',
                      'Destination Address',
                      'Source Address',
                      'Message',
                      'Delivery State',
                      'Created',
                    ]}
                    emptyText="No messages found, try expanding your search."
                    ready={ready}
                    ellipsis={{
                      0: 8,
                      3: 60,
                    }}
                    dateFormat={dateFormat}
                    rows={ready ? reportState.items.map(item => {
                      if (!item) {
                        return [];
                      }

                      if (reportState.summary) {
                        return [
                          item.date,
                          (item && item.messages) ? new Intl.NumberFormat().format(item.messages) : 0,
                          (item && item.parts) ? new Intl.NumberFormat().format(item.parts) : 0,
                          (item && item.dlrs && item.dlrs.ENROUTE) ? new Intl.NumberFormat().format(item.dlrs.ENROUTE) : 0,
                          (item && item.dlrs && item.dlrs.DELIVERED) ? new Intl.NumberFormat().format(item.dlrs.DELIVERED) : 0,
                          (item && item.dlrs && item.dlrs.UNDELIVERABLE) ? new Intl.NumberFormat().format(item.dlrs.UNDELIVERABLE) : 0,
                          (item && item.dlrs && item.dlrs.DELETED) ? new Intl.NumberFormat().format(item.dlrs.DELETED) : 0,
                          (item && item.dlrs && item.dlrs.EXPIRED) ? new Intl.NumberFormat().format(item.dlrs.EXPIRED) : 0,
                          (item && item.dlrs && item.dlrs.OPTOUT) ? new Intl.NumberFormat().format(item.dlrs.OPTOUT) : 0,
                        ];
                      }

                      const dlr = item.deliveryReports;

                      let status = 'ENROUTE';

                      if (dlr) {
                        if (dlr.DELIVERED) {
                          status = 'DELIVERED';
                        } else if (dlr.EXPIRED) {
                          status = 'EXPIRED';
                        } else if (dlr.UNDELIVERABLE) {
                          status = 'UNDELIVERABLE';
                        } else if (dlr.DELETED) {
                          status = 'DELETED';
                        } else if (dlr.OPTOUT) {
                          status = 'OPTOUT';
                        }
                      }

                      return [
                        item.id,
                        <PrettifyAddress>{item.destAddr}</PrettifyAddress>,
                        <PrettifyAddress strict={false}>{item.sourceAddr}</PrettifyAddress>,
                        item.message,
                        <span className={classes[status]}>{status}</span>,
                        item.created
                      ];
                    }) : []}
                    hasNext={ready && reportState.hasNext}
                    pageSize={ready ? reportState.pageSize : 0}
                    pageNumber={ready ? reportState.pageNumber : 0}
                    pagination={handlePagination}
                    container={false}
                    primaryAction={{
                      handleClick: handlePrimaryClick,
                      title: 'message id',
                      replacements: [{
                        key: 'messageId',
                        value: 0,
                      }]
                    }}
                />
                )}
                {reportState && reportState.dlr && (
                  <>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          Messages Sent
                        </Typography>
                        <Typography variant="body1">
                          {new Intl.NumberFormat({ style: 'decimal', maximumFractionDigits: 0 }).format(reportState.messages)}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography variant="subtitle2">
                          Message Parts
                        </Typography>
                        <Typography variant="body1">
                          {new Intl.NumberFormat({ style: 'decimal', maximumFractionDigits: 0 }).format(reportState.parts)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">
                          Delivery Receipts
                        </Typography>
                      </Grid>
                      {Object.keys(reportState.dlr).map(dlr => (
                        <Grid item md={2} xs={6} key={dlr}>
                          <Typography variant="body1">
                            <span className={classes[dlr]}>{dlr}</span>
                          </Typography>
                          <Typography variant="body2">
                            {new Intl.NumberFormat({ style: 'decimal', maximumFractionDigits: 0 }).format(reportState.dlr[dlr])}
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
            </>
          </ContainerWrapper>
          <DialogWrapper
            open={modalState.open}
            title="Outbound Message"
            handleToggle={() => toggleModal(false)}
            close="Close"
          >
            <>
            {modalState && modalState.id && (
              <>
                <Typography variant="subtitle2">
                  Message ID
                </Typography>
                <Typography variant="body1">
                  {modalState.id}
                </Typography>

                <Divider light variant="middle" className={classes.divider} />

                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <>
                    <Typography variant="subtitle2">
                      Source Address
                    </Typography>
                    <Typography variant="body1">
                      <PrettifyAddress>{modalState.sourceAddr}</PrettifyAddress>
                    </Typography>
                    </>
                  </Grid>
                  <Grid item xs={6}>
                    <>
                    <Typography variant="subtitle2">
                      Destination Address
                    </Typography>
                    <Typography variant="body1">
                      <PrettifyAddress>{modalState.destAddr}</PrettifyAddress>
                    </Typography>
                    </>
                  </Grid>
                </Grid>

                <Divider light variant="middle" className={classes.divider} />

                <Typography variant="subtitle2">
                  Body
                </Typography>
                <Typography variant="body1">
                  {modalState.message}
                </Typography>

                {modalState.tags && Object.keys(modalState.tags).length > 0 && (
                  <>
                    <Divider light variant="middle" className={classes.divider} />
                    <Typography variant="subtitle2">
                      Tags
                    </Typography>
                    <Grid container spacing={3}>
                      {modalState.tags && Object.keys(modalState.tags).map(key => (
                        <Grid item xs={6} key={key}>
                          <>
                            <Typography variant="body1">
                              {key}
                            </Typography>
                            <Typography variant="body2">
                              {modalState.tags[key]}
                            </Typography>
                          </>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}

                {modalState.deliveryReports && Object.keys(modalState.deliveryReports).length > 0 && (
                  <>
                    <Divider light variant="middle" className={classes.divider} />
                    <Typography variant="subtitle2">
                      Delivery Reports
                    </Typography>
                    <Grid container spacing={3}>
                      {modalState.deliveryReports && Object.keys(modalState.deliveryReports).map(key => (
                        <Grid item xs={6} key={key}>
                          <Typography variant="body1">
                            <span className={classes[key]}>{key}</span>
                          </Typography>
                          <Typography variant="body2">
                            <Moment format={dateFormat}>
                              {modalState.deliveryReports[key]}
                            </Moment>
                          </Typography>
                        </Grid>
                      ))}
                    </Grid>
                  </>
                )}
              </>
            )}
            </>
          </DialogWrapper>
        </>
      )}
    </>
  );
};

const OutboundFetch = ApiHOC(
  Outbound,
);

export default OutboundFetch;
