import React, { useEffect, useState, useContext } from 'react';
import Alert from '@material-ui/lab/Alert';
import LinearProgress from '@material-ui/core/LinearProgress';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Moment from 'react-moment';
import ApiHOC from '../../../lib/ApiHOC';
import { debug } from '../../../lib/Debug';
import { useParams } from 'react-router-dom';
import ContainerWrapper from '../../../components/ContainerWrapper';
import TableWrapper from '../../../components/TableWrapper';
import NumberFormat from '../../../components/NumberFormat';
import { FlashContext } from '../../../context/FlashContext';
import {
  ROUTER_RESTRICTED_INDEX,
  ROUTER_RESTRICTED_PAYMENT_INDEX,
} from '../../../routes/Constants';

const uri = '/restricted/payment/history/item';

const PaymentItem = (props) => {
  const [pushFlashState] = useContext(FlashContext);
  const [state, setState] = useState({
    ready: false,
    processing: false,
  });
  const { paymentId } = useParams();

  useEffect(() => {
    document.title = 'Payment Item';

    if (!state.init && props.data) {
      setState({
        ...state,
        ...props.data,
        init: true,
      });

      props
        .handleRequest(
          'data',
          `${uri}/${paymentId}`,
        )
        .then(resp => {
          setState({
            ...state,
            ready: true,
            data: resp,
            init: true,
          });
        })
        .catch(err => {
          debug('PaymentItem-useEffect', 'error', err.message);
        });
    }
  }, [props, state, setState, pushFlashState, paymentId]);

  const handleClick = async status => {
    setState({
      ...state,
      processing: true,
    });

    if (status === 'accept' || status === 'decline') {
      await props.handleRequest(null, `${uri}/${paymentId}/${status}`, 'patch')
        .then(resp => {
          if (resp.status === 'ACCEPTED') {
            pushFlashState(
              'Payment approved',
              'success',
            );
          } else if (resp.status === 'DECLINED') {
            pushFlashState(
              'Payment declined',
              'error',
            );
          } else if (resp.status === 'CANCELLED') {
            pushFlashState(
              'Payment cancelled',
              'success',
            );
          }
        })
        .catch(err => {
          console.log(err.message);
          pushFlashState(
            'Unable to process payment',
            'error',
          );
        });
    }

    setState({
      ...state,
      processing: false,
    });
  }

  const { ready } = state;

  if (!ready) {
    return <LinearProgress />;
  }

  const maxMind = state.data.purchase._.maxMind;

  const isReady = Boolean(state.ready && state.data);

  const { status } = state.data.purchase.user;

  return (
    <ContainerWrapper
      ready={isReady}
      title="Pending Payments"
      breadcrumbs={[
        [
          'Restricted',
          ROUTER_RESTRICTED_INDEX,
        ],
        [
          'Pending Payments',
          ROUTER_RESTRICTED_PAYMENT_INDEX,
        ],
        [
          'Payment',
        ]
      ]}
    >
      {status === 'DECLINED' && (
        <Alert severity="error">Payment was accepted but rejected by the bank.</Alert>
      )}
      {status === 'ACCEPTED' && (
        <Alert severity="success">Payment was accepted and approved by the bank.</Alert>
      )}
      {status === 'CANCELLED' && (
        <Alert severity="success">Payment was declined.</Alert>
      )}
      <Grid container spacing={3}>
        <Grid item md={6}>
          <TableWrapper
            headers={['Username', state.data.email]}
            ready={true}
            pageNumber={1}
            pagination={() => {}}
            rows={
              [
                ['Name', `${state.data.firstName} ${state.data.lastName}`],
                ['Balance', <><NumberFormat>{state.data.balance}</NumberFormat> {state.data.payment}PAID</>],
                ['Account Created', <Moment format="D MMM YYYY">{state.data.created}</Moment>],
                ['Account Status', state.data.status === true ? 'Active' : 'Inactive'],
                ['Email Verified', state.data.verified === true ? 'Verified' : 'Unverified'],
                ['Purchase', <NumberFormat>{state.data.purchase._.amount}</NumberFormat>],

              ]
            }
          />
        </Grid>
        <Grid item md={6}>
          <TableWrapper
            headers={['Risk Score', maxMind.score.riskScore.toString()]}
            ready={true}
            pageNumber={1}
            pagination={() => {}}
            rows={
              [
                ['IP Risk Score', maxMind.score.ipAddress.risk],
                ['Local Time', <Moment format="LLLL">{maxMind.insights.ipAddress.location.localTime}</Moment>],
                ['IP Country', maxMind.insights.ipAddress.registeredCountry.names.en],
                ['Address',
                  <>
                    {maxMind.insights.ipAddress.subdivisions[0].names.en},&nbsp;
                    {maxMind.insights.ipAddress.city.names.en} ({maxMind.insights.ipAddress.postal.code}),<br />
                    {maxMind.insights.ipAddress.registeredCountry.names.en}&nbsp;<br />
                    <a href={`https://www.google.com/maps/@${maxMind.insights.ipAddress.location.latitude},${maxMind.insights.ipAddress.location.longitude}`} target="_blank" rel="noopener noreferrer">Google Maps</a>
                  </>
                ],
                ['Country High Risk', <>{maxMind.insights.ipAddress.country.isHighRisk === true ? 'Yes' : 'No'}</>],
              ]
            }
          />
        </Grid>
        <Grid item md={12}>
          {maxMind.insights.ipAddress.traits.userType.organization}
          ['Is Hosting Provider', <>{maxMind.insights.ipAddress.traits.userType.isHostingProvider === true ? 'Yes' : 'No'}</>]
          ['Is Anonymous Proxy', <>{maxMind.insights.ipAddress.traits.userType.isAnonymousProxy === true ? 'Yes' : 'No'}</>]
          ['Is Public Proxy', <>{maxMind.insights.ipAddress.traits.userType.isPublicProxy === true ? 'Yes' : 'No'}</>]
          ['Is Legitimate Proxy', <>{maxMind.insights.ipAddress.traits.userType.isLegitimateProxy === true ? 'Yes' : 'No'}</>]
          ['Is Satellite Provider', <>{maxMind.insights.ipAddress.traits.userType.isSatelliteProvider === true ? 'Yes' : 'No'}</>]
          ['Is Anonymous', <>{maxMind.insights.ipAddress.traits.userType.isAnonymous === true ? 'Yes' : 'No'}</>]
          {maxMind.insights.email.isHighRisk}
          {maxMind.insights.email.isFree}
          {maxMind.insights.email.firstSeen}
        </Grid>
        {status === 'PENDING' && (
          <>
            <Grid item md={6}>
              <Button disabled={state.processing} onClick={() => handleClick('decline')} color="secondary" variant="contained">
                Decline
              </Button>
            </Grid>
            <Grid item md={6} style={{ textAlign: 'right' }}>
              <Button disabled={state.processing}  onClick={() => handleClick('accept')} color="primary" variant="contained">
                Accept
              </Button>
            </Grid>
          </>
        )}
      </Grid>
    </ContainerWrapper>
  );
};

const PaymentItemFetch = ApiHOC(
  PaymentItem,
);

export default PaymentItemFetch;
