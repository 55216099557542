import React, { useEffect, useState, useRef, useContext } from 'react';
import moment from 'moment';
import Input from '../../../../components/forms/Input';
import InputSelect from '../../../../components/forms/InputSelect';
import ApiHOC from '../../../../lib/ApiHOC';
import { Grid } from '@material-ui/core';
import FormWrapper from '../../../../components/forms/FormWrapper';
import { FlashContext } from '../../../../context/FlashContext';
import { debug } from '../../../../lib/Debug';

import { ROUTER_PAYMENT_CARD_INDEX, ROUTER_PAYMENT_INDEX, ROUTER_PAYMENT_CARD_ADD, ROUTER_INDEX } from '../../../../routes/Constants';

const CardModify = (props) => {
  const [pushFlashState] = useContext(FlashContext);
  const [epsState, setEpsState] = useState({});
  const [errorState, setErrorState] = useState({});
  const [inputState, setInputState] = useState({
    cardName: '',
    cardNumber: '',
    expiryMonth: '01',
    expiryYear: (new Date()).getFullYear().toString().substr(2),
    cvv: null,
  });
  const [processState, setProcessState] = useState({});
  const formRef = useRef();

  useEffect(() => {
    document.title = 'Create Credit Card';

    if (epsState && epsState.EPS_FINGERPRINT) {
      formRef.current.submit();
    }
  }, [epsState, formRef]);

  const handleChange = e => {
    const { id, value } = e.target;

    setInputState({
      ...inputState,
      [id]: value,
    });

    setErrorState({
      ...errorState,
      [id]: null,
    });
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    setProcessState({
      ...processState,
      form: true,
    });

    const newErrorState = {};

    const { cardName, cardNumber, expiryMonth, expiryYear, cvv } = inputState;
    const currentTime = moment();

    if (cardName === '') {
      newErrorState.cardName = 'Name on card cannot be blank';
    }

    if (
      !cardNumber.toString(/^4[0-9]{12}(?:[0-9]{3})?$/) || // VISA
      !cardNumber.toString(/^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$/) // MasterCard
    ) {
      newErrorState.cardNumber = 'Invalid credit card number, we only accept VISA & MasterCard';
    }

    if (
      !expiryMonth.toString().match(/^([0-9]+)$/) ||
      parseInt(expiryMonth, 10) > 12 ||
      parseInt(expiryMonth, 10) < 1
    ) {
      newErrorState.expiryMonth = 'Invalid expiry month, must be between 01 - 12';
    }

    if (
      !expiryYear.toString().match(/^([0-9]{2})$/) ||
      parseInt(expiryYear, 10) < currentTime.format('YY')
    ) {
      newErrorState.expiryYear = `Invalid expiry year, must be greater than or equal to ${currentTime.format('YY')}`;
    }

    if (
      !cvv.toString().match(/^([0-9]{3})$/)
    ) {
      newErrorState.cvv = 'Invalid cvv, must be three digits';
    }

    setErrorState({
      ...newErrorState,
    });

    if (Object.keys(newErrorState).length === 0) {
      const { cardNumber } = inputState;
      const result = await props.handleRequest(null, '/payment/cards/crn', 'post', {}, { cardName, cvv: cvv.toString(), lastDigits: cardNumber.substr(-4) })
        .then(resp => {
          setEpsState({
            ...resp,
            EPS_CARDNUMBER: cardNumber,
            EPS_EXPIRYMONTH: inputState.expiryMonth,
            EPS_EXPIRYYEAR: inputState.expiryYear,
          });

          return true;
        })
        .catch(err => {
          debug('Modify-handleSubmit', 'error', err.message);

          pushFlashState(
            'Unable to save credit card details',
            'error',
          );

          return false;
        });

      if (result) {
        return; // Don't update form state
      }
    }

    setProcessState({
      ...processState,
      form: false,
    });
  }

  const months = [1,2,3,4,5,6,7,8,9,10,11,12];
  const years = [];

  for (let i = 0; i < 15; i++) {
    const d = new Date();
    years.push(d.getFullYear() + i);
  }

  return (
    <>
      <FormWrapper
        onSubmit={handleSubmit}
        disabled={processState.form}
        action="Save Card Details"
        title="Payment"
        breadcrumbs={[
          [
            'Dashboard',
            ROUTER_INDEX,
          ],
          [
            'Payments',
            ROUTER_PAYMENT_INDEX,
          ],
          [
            'Credit Cards',
            ROUTER_PAYMENT_CARD_INDEX,
          ],
          [
            'Add Card',
            ROUTER_PAYMENT_CARD_ADD,
          ]
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Input
              label="Name on card"
              id="cardName"
              value={inputState.cardName}
              disabled={processState.form}
              error={!!errorState.cardName}
              helperText={errorState.cardName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              label="Credit Card Number"
              id="cardNumber"
              value={inputState.cardNumber}
              disabled={processState.form}
              error={!!errorState.cardNumber}
              helperText={errorState.cardNumber}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={4}>
            <InputSelect
              label="Expiry Month"
              id="expiryMonth"
              value={inputState.expiryMonth}
              disabled={processState.form}
              error={!!errorState.expiryMonth}
              helperText={errorState.expiryMonth}
              handleChange={v => handleChange({ target: { id: 'expiryMonth', value: v }})}
              items={months.map(i => { const month = i.toString().padStart(2, '0'); return { label: month, value: month } })}
            />
          </Grid>
          <Grid item xs={4}>
            <InputSelect
              label="Expiry Year"
              id="expiryYear"
              value={inputState.expiryYear}
              disabled={processState.form}
              error={!!errorState.expiryYear}
              helperText={errorState.expiryYear}
              handleChange={v => handleChange({ target: { id: 'expiryYear', value: v }})}
              items={years.map(i => ({ label: i.toString(), value: parseInt(i.toString().substr(2), 10) }))}
            />
          </Grid>
          <Grid item xs={4}>
            <Input
              label="CVV"
              id="cvv"
              value={inputState.cvv}
              disabled={processState.form}
              error={!!errorState.cvv}
              helperText={errorState.cvv}
              onChange={handleChange}
            />
          </Grid>
        </Grid>
      </FormWrapper>
      <form
        method="post"
        action="https://demo.transact.nab.com.au/directpost/crnmanage"
        ref={formRef}
      >
        { epsState.EPS_FINGERPRINT && (
          <>
            { Object.keys(epsState).map(epsKey => (
              <input type="hidden" name={epsKey} key={epsKey} value={epsState[epsKey]} />
            ))}
            <input type="hidden" name="EPS_REDIRECT" value="true" />
          </>
        )}
      </form>
    </>
  )
}

const CardModifyFetch = ApiHOC(
  CardModify,
);

export default CardModifyFetch;
