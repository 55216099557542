import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CircularProgress from '@material-ui/core/CircularProgress';

const DialogWrapper = (props) => (
  <Dialog open={props.open} onClose={props.handleToggle} aria-labelledby="wrapped-dialog-title">
    <DialogTitle id="wrapped-dialog-title">{props.title}</DialogTitle>
    <DialogContent style={{paddingBottom: '10px'}}>
      <>
        {props.children}
      </>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleToggle} color="primary">
        {props.close}
      </Button>
      { props.primaryAction && (
        <Button
          onClick={props.primaryAction.handleClick}
          color={props.primaryAction.class || 'primary' }
          disabled={props.primaryAction.processing}
        >
          {props.primaryAction.processing && <CircularProgress size={24} color={props.primaryAction.class || 'primary' } />}
          {!props.primaryAction.processing && props.primaryAction.label}
        </Button>
      )}
    </DialogActions>
  </Dialog>
);

export default DialogWrapper;

DialogWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  close: PropTypes.string,
  children: PropTypes.node.isRequired,
  primaryAction: PropTypes.shape({
    label: PropTypes.string.isRequired,
    class: PropTypes.string,
    handleClick: PropTypes.func.isRequired,
  })
};

DialogWrapper.defaultProps = {
  close: 'Close',
  primaryAction: undefined,
};
