import React from 'react';
import TextField from '@material-ui/core/TextField';

const Input = (props) => (<TextField
  fullWidth
  autoFocus={props.autoFocus}
  name={props.id}
  variant="outlined"
  required={props.required}
  id={props.id}
  label={props.label}
  defaultValue={props.value}
  error={props.error}
  helperText={props.error ? props.helperText : null}
  onChange={props.onChange}
  disabled={props.disabled}
  type={props.type}
  InputProps={{
    ...props.InputProps,
    readOnly: props.readOnly,
  }}
/>);

export default Input;

Input.defaultProps = {
  autoFocus: false,
  required: false,
  value: null,
  onChange: () => {},
  helperText: null,
  error: false,
  disabled: false,
  type: 'text',
  readOnly: false,
  InputProps: {},
};
