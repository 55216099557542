import React, { Component } from 'react';
import { UserContext } from '../context/UserContext';
import { wrapper } from './Request';
import { validateToken } from './Token';
import { debug } from './Debug';

const ApiHOC = (WrappedComponent, fetchProps) => {
  return class extends Component {
    static contextType = UserContext;

    constructor(props) {
      super(props);
      this.state = {};
    }

    async componentDidMount() {
      const context = this.context;
      const userState = context[0];
      const pushFlashState = context[2];

      if (userState && userState.accessToken) {
        const { accessToken } = userState;

        await this.setState({ accessToken });

        if (accessToken && fetchProps && fetchProps.uri) {
          await this.validateRefreshJwt();

          await this.handleRequest(
            userState.accessToken,
            'data',
            fetchProps.uri,
            fetchProps.method
          )
          .catch(err => {
            debug('ApiHOC-componentDidMount', 'error', err.message);

            pushFlashState('Unable to fetch details', 'error');
          });
        } else {
          await this.setState({ data: {} });
        }
      }
    }

    async handleRequest(accessToken, stateKey, uri, method, query, body) {
      const updatedAccessToken = await this.validateRefreshJwt();

      debug('ApiHOC-handleRequest', 'debug-token', updatedAccessToken);

      if (updatedAccessToken) {
        accessToken = updatedAccessToken;
      }

      const resp = await wrapper(
        accessToken ? accessToken : null,
        uri,
        method ? method.toUpperCase() : 'GET',
        query,
        body,
      );

      if (resp) {
        if (stateKey) {
          this.setState({
            [stateKey]: resp,
          });
        }

        if (resp.accessToken && resp.refreshToken && resp.uuid) {
          const [userState, setUserState] = this.context;

          await setUserState({
            ...userState,
            ...resp,
          });
        }

        return resp;
      }
    }

    async validateRefreshJwt() {
      const [userState, setUserState] = this.context;

      if (userState.accessToken && userState.refreshToken) {
        const resp = await validateToken(userState.uuid, userState.accessToken, userState.refreshToken);

        if (resp && resp.accessToken) {
          setUserState({
            ...userState,
            ...resp,
          });

          return resp.accessToken;
        }

        return userState.accessToken;
      }
    }

    render() {
      const { accessToken } = this.state;

      return <WrappedComponent
        data={this.state.data}
        handleRequest={
          (stateKey, uri, method, query, body) => this.handleRequest(accessToken, stateKey, uri, method, query, body)
        }
        {...this.props}
      />;
    }
  };
};

export default ApiHOC;
