import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import Input from '../../components/forms/Input';
import { FlashContext } from '../../context/FlashContext';
import ApiHOC from '../../lib/ApiHOC';

const PasswordChange = (props) => {
  const [pushFlashState] = useContext(FlashContext);

  const [form, setForm] = useState({});
  const [process, setProcess] = useState({});
  const [error, setError] = useState({});

  const handleToggle = () => {
    setForm({});
    setProcess({});
    setError({});

    props.handleToggle();
  }

  const handleChange = (e, updateError = true) => {
    const { id, value } = e.target;

    setForm({ ...form, [id]: value });
    if (updateError) {
      setError({ ...error, [id]: false });
    }
  }

  const handleSubmit = async e => {
    if (e) {
      e.preventDefault();
    }

    setError({});
    setProcess({ ...process, form: true });

    const newError = {};

    if (props.currentPassword && (!form.currentPassword || form.currentPassword.length < 5)) {
      newError.currentPassword = true;
    }

    if (!form.password || form.password.length < 5) {
      newError.password = true;
    }

    if (!form.passwordConfirm || form.password !== form.passwordConfirm) {
      newError.passwordConfirm = true;
    }

    setError(newError);

    if (Object.keys(newError).length === 0) {
      const body = {
        password: form.password,
      };

      if (props.currentPassword) {
        body.currentPassword = form.currentPassword;
      }

      await props.handleRequest(null, '/profile/password', 'patch', {}, body)
        .then(() => {
          pushFlashState(
            'Password updated',
            'success',
          );

          handleToggle();
        })
        .catch(() => {
          if (props.currentPassword) {
            setError({
              currentPassword: true,
            });
          }

          pushFlashState(
            'Unable to update password, try again',
            'error',
          );
        });
    }

    setProcess({ ...process, form: false });
  }

  return (
    <Dialog open={props.open} onClose={handleToggle} aria-labelledby="form-dialog-title">
      <form onSubmit={handleSubmit} noValidate>
        <DialogTitle id="form-dialog-title">Password change</DialogTitle>
        <DialogContent>
          <>
            <Grid container spacing={3}>
              {props.currentPassword && (
                <Grid item xs={12}>
                <Input
                  id="currentPassword"
                  label="Current Password"
                  type="password"
                  value={form.currentPassword}
                  onChange={handleChange}
                  required={true}
                  disabled={process.currentPassword}
                  error={!!error.currentPassword}
                  helperText="Current password is required"
                />
              </Grid>
              )}
              <Grid item xs={12}>
                <Input
                  id="password"
                  label="New Password"
                  type="password"
                  value={form.password}
                  onChange={handleChange}
                  required={true}
                  disabled={process.password}
                  error={!!error.password}
                  helperText="Password is a bit weak, try making it a bit longer"
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id="passwordConfirm"
                  label="Confirm New Password"
                  type="password"
                  value={form.passwordConfirm}
                  onChange={handleChange}
                  required={true}
                  disabled={process.passwordConfirm}
                  error={!!error.passwordConfirm}
                  helperText="Passwords do not match"
                />
              </Grid>
            </Grid>
          </>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleToggle} color="primary">
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            disabled={process.form}
          >
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

const PasswordChangeFetch = ApiHOC(
  PasswordChange,
);

export default PasswordChangeFetch;

PasswordChange.propTypes = {
  open: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
};
