import qs from 'querystring';
import { debug } from './Debug';

const wrapper = async (accessToken, path, method, query, body) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  };

  const opts = {
    method: method.toUpperCase(),
    headers,
    body: JSON.stringify(body),
  };

  if (method.toLowerCase() === 'get') {
    delete opts.body;
  }

  if (
    accessToken
    && (
      path !== '/auth/logout'
      && path !== '/auth/verify-email'
    )
  ) {
    opts.headers.authorization = `Bearer ${accessToken}`;

    path = `/private/token${path}`;
  }

  let querystring = '';

  if (query) {
    querystring = `?${qs.stringify(query)}`;
  }

  return fetch(`${process.env.REACT_APP_DOMAIN_URI}${path}${querystring}`, opts)
    .then(async data => {
      if (data.status !== 200) {
        let dataError = {};

        try {
          dataError = await data.json();
        } catch (err) {
          debug('wrapper-fetch', 'error', err.message);
        }

        if (dataError && dataError.message) {
          throw new Error(dataError.message);
        }

        throw new Error('Unable to handle request');
      }

      return data.json();
    });
}

export {
  wrapper,
}
