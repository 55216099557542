import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import Paper from '@material-ui/core/Paper';
import Skeleton from '@material-ui/lab/Skeleton';
import Grid from '@material-ui/core/Grid';
import {
  Chart,
  ArgumentAxis,
  AreaSeries,
} from '@devexpress/dx-react-chart-material-ui';
import classNames from 'clsx';
import { withStyles } from '@material-ui/core/styles';

import PrimaryItem from './PrimaryItem';
import { usePaper } from '../../helpers/Classes';
import { receiptAndColours } from '../../helpers/DeliveryReceipts';

const setStyle = (style) => {
  const wrap = withStyles({ root: style });
  return Target => wrap(({ classes, className, ...restProps }) => (
    <Target className={classNames(classes.root, className)} {...restProps} />
  ));
};

const ChartRoot = setStyle({
  paddingRight: '20px',
})(Chart.Root);

const format = () => tick => tick;

const SummaryChart = ({ items, balance, account }) => {
  const classes = usePaper();
  const [state, setState] = useState({});

  useEffect(() => {
    if (!items || state.ready) {
      return;
    }

    const cleanData = [];
    const dlrTotals = {};
    let messages = 0;
    let parts = 0;

    items.forEach(item => {
      const d = {
        date: item.date,
        messages: item.messages,
        parts: item.parts,
      };

      messages += item.messages;
      parts += item.parts;

      receiptAndColours.forEach(dlr => {
        const status = dlr[0];
        d[status] = 0;

        if (!dlrTotals[status]) {
          dlrTotals[status] = 0;
        }

        dlrTotals[status] += item.dlrs[status];

        if (item.dlrs && item.dlrs[status]) {
          d[status] = item.dlrs[status];
        };
      });

      cleanData.push(d);
    });

    setState({
      data: cleanData,
      ready: true,
      messages,
      parts,
      dlrTotals,
    });
  }, [setState, state, items]);

  const { data, dlrTotals, messages, parts, ready } = state;

  return (
    <>
      <Grid
        container
        spacing={3}
      >
        <PrimaryItem ready={ready} primary title="Parts">{parts ? parts : 0}</PrimaryItem>
        <PrimaryItem ready={ready}  primary title="Messages">{messages ? messages : 0}</PrimaryItem>
        {
          balance
          && account
          && account.payment
          ? (
            <PrimaryItem
              title="Balance"
              ready={ready}
            >
              {
                account.payment === 'PRE'
                ? new Intl.NumberFormat(
                    'en-AU', {
                      style: 'currency',
                      currency: 'AUD'
                    }
                  )
                  .format(parseFloat(account.balance))
                : '-'
              }
            </PrimaryItem>
          )
          : <></>
        }
        {receiptAndColours.filter(dlr => {
          // eslint-disable-next-line
          const [status, _colour, forced] = dlr;

          return (forced || (
            dlrTotals &&
            dlrTotals[status] &&
            dlrTotals[status] > 0
          ));
        })
        .map(dlr => {
          const [status, colour] = dlr;
          const counter = dlrTotals && dlrTotals[status] ? dlrTotals[status] : 0;

          return (
            <PrimaryItem
              title={status}
              color={colour}
              key={status}
              ready={ready}
            >
              {counter}
            </PrimaryItem>
          );
        })}
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          { ready ? (
            <Paper className={classes.paper}>
              <Chart
                data={data}
                rootComponent={ChartRoot}
                height={100}
              >
                <ArgumentAxis tickFormat={format} />
                {receiptAndColours.map(dlr => {
                  const [status, colour] = dlr;

                  return (
                    <AreaSeries
                      name={status}
                      valueField={status}
                      argumentField="date"
                      key={status}
                      color={colour}
                    />
                  );
                })}
              </Chart>
            </Paper>) : <Skeleton variant="rect" width="100%" height={100} />
          }
        </Grid>
      </Grid>
    </>
  )
}

export default SummaryChart;

SummaryChart.propTypes = {
  item: PropTypes.arrayOf(
    PropTypes.shape({
      messages: PropTypes.number.isRequired,
      parts: PropTypes.number.isRequired,
      dlrs: PropTypes.object.isRequired,
      date: PropTypes.string.isRequired,
    }),
  ),
  account: PropTypes.shape({
    balance: PropTypes.number,
    payment: PropTypes.string,
  }),
  balance: PropTypes.bool,
};

SummaryChart.defaultProps = {
  balance: true,
  account: {},
};
