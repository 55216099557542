import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const NoItems = ({ children }) => (
  <Typography variant="subtitle1">
    {children}
  </Typography>
);

export default NoItems;

NoItems.propTypes = {
  children: PropTypes.node.isRequired,
};
