import React, { useContext, useEffect } from 'react';
import { FlashContext } from '../context/FlashContext';
import clsx from 'clsx';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles } from '@material-ui/core/styles';

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
};

const useStyles = makeStyles(theme => ({
  close: {
    padding: theme.spacing(0.5),
  },
  success: {
    backgroundColor: green[600],
  },
  error: {
    backgroundColor: theme.palette.error.dark,
  },
  info: {
    backgroundColor: theme.palette.primary.main,
  },
  warning: {
    backgroundColor: amber[700],
  },
  icon: {
    fontSize: 20,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
}));

const FlashMessages = () => {
  const classes = useStyles();
  const [flashState, flashStatePop] = useContext(FlashContext).slice(1);

  useEffect(() => {
    if (flashState.length > 1) {
      flashStatePop();
    }
  }, [flashState, flashStatePop]);

  const clearMessage = () => {
    flashStatePop();
  }

  const handleClose = (_event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    clearMessage();
  };

  return (
    <>
      { flashState.map(flash => {
        const flashVariant = (flash && flash.variant) ? flash.variant : 'info'
        const variant = ['error', 'info', 'success', 'warning'].includes(flashVariant) ? flashVariant : 'info';
        const Icon = variantIcon[variant];

        return (
          <Snackbar
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            open={true}
            onClose={handleClose}
            autoHideDuration={6000}
            key={flash.key}
          >
            <SnackbarContent
              className={clsx(classes[variant], classes.margin)}
              aria-describedby="client-snackbar"
              message={
                <span id="client-snackbar" className={classes.message}>
                  <Icon className={clsx(classes.icon, classes.iconVariant)} />
                  {flash.message}
                </span>
              }
              action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={handleClose}>
                  <CloseIcon className={classes.icon} />
                </IconButton>,
              ]}
            />
          </Snackbar>
        );
      })}
    </>
  );
};

export default FlashMessages;
