import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';

import Input from '../../components/forms/Input';
import AuthForm from '../../components/AuthForm';
import { UserContext } from '../../context/UserContext';
import ApiHOC from '../../lib/ApiHOC';
import { ROUTER_FORGOT_PASSWORD, ROUTER_REGISTER } from '../../routes/Constants';

const Login = (props) => {
  const pushFlashState = useContext(UserContext)[2];

  useEffect(() => {
    document.title = 'Login';
  });

  const [form, setForm] = useState({});
  const [process, setProcess] = useState({});
  const [error, setError] = useState({});

  const handleChange = e => {
    const { id, value } = e.target;

    setForm({ ...form, [id]: value });
    setError({ ...error, [id]: false });
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    setError({});
    setProcess({ ...process, form: true });

    const newError = {};

    if (!form.email || !form.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      newError.email = true;
    }

    if (!form.password) {
      newError.password = true;
    }

    setError(newError);

    if (Object.keys(newError).length === 0) {
      const body = {
        username: form.email,
        password: form.password,
      };

      try {
        await props.handleRequest('login', '/auth/login', 'post', {}, body);

        return;
      } catch (err) {
        pushFlashState(
          'Invalid username and password combination',
          'error',
        );
      }

      localStorage.clear();
    }

    setProcess({ ...process, form: false });
  }

  return (
    <AuthForm
      title="Login"
      disabled={process.form}
      onSubmit={handleSubmit}
      rightVariantText="Forgot Password"
      rightVariantLink={ROUTER_FORGOT_PASSWORD}
      leftVariantText="Need an account? Register"
      leftVariantLink={ROUTER_REGISTER}
      ready={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Input
            id="email"
            label="Email Address"
            onChange={handleChange}
            required={true}
            disabled={process.form}
            error={!!error.email}
            helperText="Invalid email address"
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="password"
            label="Password"
            onChange={handleChange}
            required={true}
            disabled={process.form}
            error={!!error.password}
            helperText="Must enter your password"
            type="password"
          />
        </Grid>
      </Grid>
    </AuthForm>
  );
}

const LoginFetch = ApiHOC(
  Login,
);

export default LoginFetch;
