import React, { useEffect, useState } from 'react';
import ApiHOC from '../../../lib/ApiHOC';
import ContainerWrapper from '../../../components/ContainerWrapper';
import { ROUTER_API_INDEX, ROUTER_API_CREATE, ROUTER_INDEX, ROUTER_API_EDIT } from '../../../routes/Constants';
import TableWrapper from '../../../components/TableWrapper';

const ApiIndex = (props) => {
  const [state, setState] = useState({
    ready: false,
  });

  useEffect(() => {
    document.title = 'API Keys';

    if (!state.ready && props.data) {
      setState({
        ...state,
        ...props.data,
        ready: true,
      });
    }
  }, [props, state, setState]);

  const { ready } = state;

  const handlePagination = async pageNumber => {
    const resp = await props.handleRequest('data', '/api', 'get', { page: pageNumber });

    await setState({
      ...state,
      ...resp,
    });
  }

  return (
    <ContainerWrapper
      ready={true}
      title="API Keys"
      button={{ text: 'Create API Key', link: ROUTER_API_CREATE }}
      breadcrumbs={[
        [
          'Dashboard',
          ROUTER_INDEX,
        ],
        [
          'API Keys',
          ROUTER_API_INDEX,
        ]
      ]}
    >
      <TableWrapper
        headers={['Name', 'Access Key', 'Created', 'Last Used']}
        ready={ready}
        rows={ready ? state.items.map(item => [item.name, item.key, item.created, item.lastUsed]) : []}
        emptyText="No API Keys created yet."
        primaryAction={{
          url: ROUTER_API_EDIT,
          title: 'name',
          replacements: [{
            key: ':token',
            value: 1,
          }]
        }}
        hasNext={ready && state.hasNext}
        pageSize={ready ? state.pageSize : 0}
        pageNumber={ready ? state.pageNumber : 0}
        pagination={handlePagination}
      />
    </ContainerWrapper>
  );
};

const ApiIndexFetch = ApiHOC(
  ApiIndex,
  { uri: '/api' }
);

export default ApiIndexFetch;
