import React, { useContext } from 'react';
import { UserContext } from '../../context/UserContext';
import { LinearProgress } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTER_INDEX, ROUTER_RESTRICTED_PAYMENT_INDEX, ROUTER_RESTRICTED_INDEX } from '../../routes/Constants';
import RestrictedPaymentIndex from './Payment/Routes';
import RestrictedIndex from './Index';

const Routes = () => {
  const [state] = useContext(UserContext);

  if (!state.ready) {
    return <LinearProgress />
  }

  if (state.ready && state.opts && state.opts.admin) {
    return (
      <Switch>
        <Route exact path={ROUTER_RESTRICTED_INDEX} component={RestrictedIndex} />
        <Route path={ROUTER_RESTRICTED_PAYMENT_INDEX} component={RestrictedPaymentIndex} />
        <Route>
          <Redirect to={ROUTER_INDEX} />
        </Route>
      </Switch>
    );
  }

  return <Redirect to={ROUTER_INDEX} />;
}

export default Routes;
