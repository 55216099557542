import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';

import Form from './Form';
import { UserContext } from '../../context/UserContext';
import ContainerWrapper from '../ContainerWrapper';
import SubmitButton from './SubmitButton';

const useStyles = makeStyles(theme => ({
  rightBottom: {
    alignSelf: 'flex-end',
    textAlign: 'right',
  },
}));

const FormWrapper = (props) => {
  const [state] = useContext(UserContext);

  if (!state.ready) {
    return <LinearProgress />
  }

  if (!props.container) {
    return (
      <FormInternal {...props}>
        {props.children}
      </FormInternal>
    )
  }

  return (
    <ContainerWrapper
      ready={true}
      title={props.title}
      subtitle={props.subtitle}
      breadcrumbs={props.breadcrumbs}
    >
      <FormInternal {...props}>
        {props.children}
      </FormInternal>
    </ContainerWrapper>
  );
};

export default FormWrapper;

FormWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  action: PropTypes.string.isRequired,
  variant: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    }),
  ),
  ready: PropTypes.bool,
  title: PropTypes.string.isRequired,
  container: PropTypes.bool,
};

FormWrapper.defaultProps = {
  disabled: false,
  variant: [],
  container: true,
  ready: true,
};

const FormInternal = (props) => {
  const classes = useStyles();

  return (
    <Form onSubmit={props.onSubmit}>
      {props.children}
      {props.ready && (
        <Grid container>
          <Grid item xs={6}>
            <SubmitButton
              disabled={props.disabled}
            >
              {props.action}
            </SubmitButton>
          </Grid>
          <Grid item xs={6} className={classes.rightBottom}>
            {props.variant && props.variant.length > 0 && (
              props.variant.map((item, i) => {
                return (
                  <>
                    <Link key={i} onClick={item.handleClick}>{item.text}</Link>
                    {' '}
                    {(i+1 < props.variant.length) && ' / '}
                  </>
                )
              })
            )}
          </Grid>
        </Grid>
      )}
    </Form>
  )
}

FormInternal.propTypes = {
  children: PropTypes.node.isRequired,
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  action: PropTypes.string.isRequired,
  variant: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      handleClick: PropTypes.func.isRequired,
    }),
  ),
};

FormInternal.defaultProps = {
  disabled: false,
  variant: [],
};
