import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import LinkWrapper from '../../lib/LinkWrapper';
import { ROUTER_INDEX } from '../../routes/Constants';

const useStyles = makeStyles(theme => ({
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
}));

const Register = () => {
  const classes = useStyles();

  useEffect(() => {
    document.title = 'Page not found';
  });

  return (
    <div className={classes.heroContent}>
      <Container maxWidth="sm">
        <Typography component="h1" variant="h2" align="center" color="textPrimary" gutterBottom>
          Page not found
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          The page you are looking for cannot be found, are you sure you're in the right place?
        </Typography>
        <Typography variant="h5" align="center" color="textSecondary" paragraph>
          Try going back&nbsp;
          <Link component={LinkWrapper} to={ROUTER_INDEX}>home</Link>
          &nbsp;to find what you're after.
        </Typography>
      </Container>
    </div>
  );
}

export default Register;
