import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ApiHOC from '../../../lib/ApiHOC';
import ContainerWrapper from '../../../components/ContainerWrapper';
import TableWrapper from '../../../components/TableWrapper';
import { FlashContext } from '../../../context/FlashContext';
import {
  ROUTER_RESTRICTED_INDEX,
  ROUTER_RESTRICTED_PAYMENT_INDEX,
} from '../../../routes/Constants';

const RestrictedPaymentIndex = (props) => {
  const history = useHistory();
  const [pushFlashState] = useContext(FlashContext);
  const [state, setState] = useState({
    ready: false,
  });

  useEffect(() => {
    document.title = 'Payment History';

    if (!state.ready && props.data) {
      setState({
        ...state,
        ...props.data,
        ready: true,
      });
    }
  }, [props, state, setState, pushFlashState]);

  const { ready } = state;

  const handlePagination = async pageNumber => {
    const resp = await props.handleRequest('data', '/restricted/payment/history/pending', 'get', { page: pageNumber });

    await setState({
      ...state,
      ...resp,
    });
  }

  const handlePrimaryClick = item => {
    const record = state.items[item.record];

    history.push(`${ROUTER_RESTRICTED_PAYMENT_INDEX}/${record.id}`)
  }

  return (
    <ContainerWrapper
      ready={state.ready}
      title="Pending Payments"
      breadcrumbs={[
        [
          'Restricted',
          ROUTER_RESTRICTED_INDEX,
        ],
        [
          'Pending Payments',
          ROUTER_RESTRICTED_PAYMENT_INDEX,
        ],
      ]}
    >
      <TableWrapper
        headers={['Payment ID', 'Amount', 'Status', 'Created']}
        ready={ready}
        ellipsis={{
          0: 8,
        }}
        rows={ready ? state.items.map(item => [
          item.id,
          new Intl.NumberFormat('en-AU', { style: 'currency', currency: 'AUD' }).format(item.amount),
          item.status,
          item.created,
        ]) : []}
        hasNext={ready && state.hasNext}
        pageSize={ready ? state.pageSize : 0}
        pageNumber={ready ? state.pageNumber : 0}
        pagination={handlePagination}
        primaryAction={{
          handleClick: handlePrimaryClick,
          title: 'payment id',
          replacements: [{
            key: 'paymentId',
            value: 0,
          }]
        }}
      />
    </ContainerWrapper>
  );
};

const RestrictedPaymentIndexFetch = ApiHOC(
  RestrictedPaymentIndex,
  { uri: '/restricted/payment/history/pending'  }
);

export default RestrictedPaymentIndexFetch;
