import React, { useEffect, useContext, useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import ContainerWrapper from '../../components/ContainerWrapper';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';
import SummaryChart from '../../components/chart/SummaryChart';
import { FlashContext } from '../../context/FlashContext';
import ApiHOC from '../../lib/ApiHOC';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';

import VpnKey from '@material-ui/icons/VpnKey';
import Description from '@material-ui/icons/Description';
import Textsms from '@material-ui/icons/Textsms';
import CreditCard from '@material-ui/icons/CreditCard';
import LinkWrapper from '../../lib/LinkWrapper';

import {
  ROUTER_API_INDEX,
  ROUTER_PAYMENT_INDEX,
} from '../../routes/Constants';
import { UserContext } from '../../context/UserContext';

const ListItemTextIcon = ({icon, text, to, external}) => (
  <ListItem
    button
    component={LinkWrapper}
    to={!external ? to : '/'}
    onClick={e => {
      if (external) {
        e.preventDefault();
        window.open(to);
      }
    }}
  >
    <ListItemIcon>
      {icon}
    </ListItemIcon>
    <ListItemText primary={text} />
  </ListItem>
);

const useStyles = makeStyles(theme => ({
  content: {
    flexGrow: 1,
    overflow: 'auto',
  },
  containerTop: {
    marginTop: theme.spacing(3),
  },
}));

const Dashboard = (props) => {
  const classes = useStyles();
  const [pushFlashState] = useContext(FlashContext);
  const [reportState, setReportState] = useState({});
  const [accountState, setAccountState] = useState({});
  const [userState] = useContext(UserContext);

  useEffect(() => {
    document.title = 'Dashboard';

    const onLoad = async () => {
      const start = moment();
      const finish = moment();

      start.subtract(7, 'days');
      start.hour(0);
      start.minute(0);
      start.second(0);
      start.millisecond(0);

      finish.hours(23);
      finish.minute(59);
      finish.second(59);
      finish.millisecond(999);

      const format = 'YYYY-MM-DDTHH:mm:ss';

      const summaryRef = props.handleRequest(null, '/report/outbound/summary', 'get', {
        startDate: start.format(format),
        finishDate: finish.format(format),
        page: 1,
      })
        .then(resp => {
          setReportState({
            ...reportState,
            ...resp,
            init: true,
          });
        })
        .catch(() => {
          pushFlashState(
            'Unable to load recent history',
            'error',
          );
        });

      const balanceRef = props.handleRequest(null, '/user/balance', 'get')
        .then(resp => {
          setAccountState({
            ...resp,
          });
        })
        .catch(() => {
          pushFlashState(
            'Unable to fetch balance',
            'error',
          );
        });

      await Promise.all([summaryRef, balanceRef]);
    };

    if (!reportState.init && props.data) {
      setReportState({
        ...reportState,
        init: true,
      });

      void onLoad();
    }
  }, [props, pushFlashState, reportState, setReportState]);

  const firstName = userState && userState.opts && userState.opts.firstName ? userState.opts.firstName : null;

  return (
  <>
    <Container className={clsx([classes.container, classes.containerTop])}>
     <Grid container spacing={3}>
        <Grid item xs={12}>
          <SummaryChart
            items={reportState && reportState.items ? reportState.items : null}
            account={accountState}
            balance
          />
        </Grid>
      </Grid>
    </Container>
    <ContainerWrapper ready={firstName ? true : false} title={`Welcome ${firstName ? firstName : ''} 📱`}>
      <Typography variant="subtitle1" gutterBottom>
        Get started using Amplisend to send and receive SMS.
      </Typography>

      <List>
        <ListItemTextIcon icon={<VpnKey />} to={ROUTER_API_INDEX} text="Create API Keys" />
        <ListItemTextIcon icon={<Description />} external to="https://developers.amplisend.com/" text="Review API Documentation" />
        <ListItemTextIcon icon={<Textsms />} external to="https://developers.amplisend.com/" text="Start Sending Messages" />
        <ListItemTextIcon icon={<CreditCard />} to={ROUTER_PAYMENT_INDEX} text="Purchase Credits" />
      </List>

    </ContainerWrapper>
  </>
  );
};

const DashboardFetch = ApiHOC(
  Dashboard,
);

export default DashboardFetch;
