import * as jwt from 'jsonwebtoken';
import { wrapper } from './Request';

const validateToken = async (uuid, accessToken, refreshToken) => {
  const pubKey = atob(process.env.REACT_APP_PUBLIC_KEY);

  try {
    const jwtOpts = await jwt.verify(accessToken, pubKey);

    return jwtOpts;
  } catch (err) {
    if (refreshToken) {
      return validateRefreshToken(uuid, refreshToken);
    }

    throw err;
  }
}

const validateRefreshToken = async (uuid, refreshToken) => {
  return wrapper(null, '/auth/refresh', 'post', {}, { uuid, refreshToken });
}

export { validateToken, validateRefreshToken };
