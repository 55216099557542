import React from 'react';
import { makeStyles, ThemeProvider, createStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import { BrowserRouter } from 'react-router-dom';
import TagManager from 'react-gtm-module';

import { UserContextProvider } from './context/UserContext';
import Header from './partials/Header';
import Routes from './routes/PublicRoutes';
import { FlashContextProvider } from './context/FlashContext';
import FlashMessages from './partials/FlashMessages';
import AmplisendTheme from './helpers/Theme';
import VerifyEmailAddressHeader from './partials/VerifyEmailAddressHeader';

const { REACT_APP_GTM, REACT_APP_GTM_AUTH, REACT_APP_GTM_PREVIEW } = process.env;

const tagManagerArgs = {
    gtmId: REACT_APP_GTM,
    auth: REACT_APP_GTM_AUTH,
    preview: REACT_APP_GTM_PREVIEW,
};

TagManager.initialize(tagManagerArgs);

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      flexDirection: 'column',
      minHeight: 'calc(100vh - 31px)',
    },
    footer: {
      padding: theme.spacing(1, 1),
      marginTop: 'auto',
      backgroundColor: theme.palette.grey[200],
      width: `calc(100%)`,
      bottom: 0,
    },
    footerTypography: {
      fontSize: 10,
      textAlign: 'right',
    },
  }),
);

export default function PermanentDrawerLeft() {
  const classes = useStyles();

  const { REACT_APP_BUILD_HASH, REACT_APP_BUILD_TIMESTAMP } = process.env;

  return (
    <>
      <BrowserRouter>
        <FlashContextProvider>
          <UserContextProvider>
            <ThemeProvider theme={AmplisendTheme}>
              <div className={classes.root}>
                <CssBaseline />
                <Header />
                <main className={classes.content}>
                  <div className={classes.toolbar} />
                  <VerifyEmailAddressHeader />
                  <FlashMessages />
                  <Routes />
                </main>
              </div>
            </ThemeProvider>
          </UserContextProvider>
        </FlashContextProvider>
      </BrowserRouter>

      <footer className={classes.footer}>
        <Container maxWidth="xl">
          <Typography className={classes.footerTypography} title={REACT_APP_BUILD_TIMESTAMP}>{REACT_APP_BUILD_HASH}</Typography>
        </Container>
      </footer>
    </>
  );
}
