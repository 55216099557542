import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

const Title = ({ children, variant }) => {
  return (
    <Typography component="h2" variant={variant} color="primary" gutterBottom>
      {children}
    </Typography>
  );
}

export default Title;

Title.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.string,
};

Title.defaultProps = {
  variant: 'h6',
}
