import React, { useState } from 'react';

const FlashContext = React.createContext([[], () => {}]);

const FlashContextProvider = (props) => {
  const [state, setState] = useState([]);

  const pushFlashState = (message, variant) => {
    const flash = {
      message,
      variant,
    };

    setState([ ...state, { ...flash, key: Date.now() }]);
  }

  const popFlashState = () => {
    if (state.length > 0) {
      setState(state.slice(1));
    }
  }

  return (
    <FlashContext.Provider value={[pushFlashState, state, popFlashState]}>
      {props.children}
    </FlashContext.Provider>
  );
};

export { FlashContext, FlashContextProvider };
