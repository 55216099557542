import React, { useState, useEffect, useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import ApiHOC from '../../../lib/ApiHOC';
import { FlashContext } from '../../../context/FlashContext';
import TableWrapper from '../../../components/TableWrapper';
import ContainerWrapper from '../../../components/ContainerWrapper';

import Header from './Header';
import PrettifyAddress from '../../../components/PrettifyAddress';

const OptOut = (props) => {
  const [pushFlashState] = useContext(FlashContext);
  const [reportState, setReportState] = useState({});
  const [inputState, setInputState] = useState({});
  const [processState, setProcessState] = useState({});

  useEffect(() => {
    document.title = 'Reports - Opt Outs';
  });

  const handlePagination = async page => {
    await handleSubmit({ ...inputState, page });
  }

  const handleSubmit = async newInputState => {
    setInputState({
      ...newInputState,
    });

    setProcessState({
      ...processState,
      request: true,
    });

    setReportState({
      result: false,
    });

    const uri = '/report/optouts/date';
    const query = {
      startDate: newInputState.startDate,
      finishDate: newInputState.finishDate,
    };

    await props.handleRequest(null, uri, 'get', query)
      .then(resp => {
        setReportState({
          result: true,
          ...resp,
        });
      })
      .catch(() => {
        pushFlashState(
          'Unable to fetch opt out data',
          'error',
        );
      });

    setProcessState({
      ...processState,
      request: false,
    });
  }

  const ready = props.data ? true : false;
  const dateFormat = 'D MMM YYYY, HH:mm:ss';

  return (
    <>
      <Header
        onSubmit={handleSubmit}
        direction="Opt Out"
        addr="Phone Number"
        slim={true}
      />
        {(processState.request || reportState.result) && (
          <>
            <ContainerWrapper
              ready={true}
              marginTop={false}
            >
              <>
                {processState.request && <LinearProgress />}
                {reportState && reportState.items && (
                  <TableWrapper
                    headers={[
                      'Phone Number',
                      'Created',
                    ]}
                    emptyText="No opt outs found, try expanding your search."
                    ready={ready}
                    ellipsis={{
                      0: 8,
                      3: 60,
                    }}
                    dateFormat={dateFormat}
                    rows={ready ? reportState.items.map(item => {
                      if (!item) {
                        return [];
                      }

                      return [
                        <PrettifyAddress>{item.phoneNumber}</PrettifyAddress>,
                        item.created
                      ];
                    }) : []}
                    hasNext={ready && reportState.hasNext}
                    pageSize={ready ? reportState.pageSize : 0}
                    pageNumber={ready ? reportState.pageNumber : 0}
                    pagination={handlePagination}
                    container={false}
                />
                )}
            </>
          </ContainerWrapper>
        </>
      )}
    </>
  );
};

const OptOutFetch = ApiHOC(
  OptOut,
);

export default OptOutFetch;
