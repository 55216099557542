import React, { useContext, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import PersonIcon from '@material-ui/icons/Person';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';

import LinkWrapper from '../lib/LinkWrapper';
import { UserContext } from '../context/UserContext';
import {
  ROUTER_API_INDEX,
  ROUTER_PROFILE_INDEX,
  ROUTER_LOGOUT,
  ROUTER_INDEX,
  ROUTER_REGISTER,
  ROUTER_LOGIN,
  ROUTER_REPORT_OUTBOUND,
  ROUTER_REPORT_INBOUND,
  ROUTER_REPORT_OPTOUT,
  ROUTER_PAYMENT_INDEX,
  // ROUTER_RESTRICTED_INDEX,
  ROUTER_WEBHOOK_INDEX,
} from '../routes/Constants';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    zIndex: theme.zIndex.drawer + 1,
    background: '#3d4977',
    width: '100%',
    marginLeft: drawerWidth,
  },
  link: {
    margin: theme.spacing(1, 1.5),
    color: '#ffffff',
    '&:hover': {
      color: '#f6f6f6',
      textDecoration: 'none',
    },
  },
  menuIcon: {
    color: '#ffffff',
  },
  toolbar: {
    ...theme.mixins.toolbar,
    paddingLeft: theme.spacing(2),
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  toolbarTitleLink: {
    color: '#ffffff',
    '&:hover': {
      textDecoration: 'none',
    }
  },
  primaryButton: {
    color: '#3d4977',
    background: '#fff',
    '&:hover': {
      background: '#000',
      color: '#fff',
    }
  },
  fullList: {
    width: 'auto',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
}));

const LinkItemWrapper = ({ to, text }) => (
  <ListItem button key={text} to={to} component={LinkWrapper}>
    <ListItemText primary={text} />
  </ListItem>
);

const Header = (props) => {
  const classes = useStyles();
  const [state] = useContext(UserContext);
  const [pageState, pageSetState] = useState({
    drawer: false,
  });

  const toggleDrawer = open => event => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    pageSetState({ drawer: open });
  }

  const smallWindow = ['xs', 'sm'].indexOf(props.width) >= 0;

  return (
    <>
      <AppBar className={classes.appBar}>
        <Toolbar position="static" className={classes.toolbar}>
          {state.ready && state.verified && (
            <Hidden mdUp>
              <IconButton edge="start" className={classes.menuIcon} aria-label="menu" onClick={toggleDrawer(!pageState.drawer)}>
                <MenuIcon />
              </IconButton>
            </Hidden>
          )}
          <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              <Link to={ROUTER_INDEX} component={LinkWrapper} className={classes.toolbarTitleLink}>
                Amplisend
              </Link>
            </Typography>
          { state.ready && (
            <>
              { !state.verified && (
                <>
                  <nav>
                    <Link variant="button" to={ROUTER_LOGIN} component={LinkWrapper} className={classes.link}>Login</Link>
                  </nav>
                  <Button href="#" variant="contained" to={ROUTER_REGISTER} component={LinkWrapper} className={clsx([classes.link, classes.primaryButton])}>
                    Register
                  </Button>
                </>
              )}
              { state.verified && (
                <>
                  <Link to={ROUTER_PROFILE_INDEX} component={LinkWrapper}>
                    <IconButton className={classes.menuIcon}>
                      <PersonIcon />
                    </IconButton>
                  </Link>
                  <nav>
                    <Link variant="button" to={ROUTER_LOGOUT} component={LinkWrapper} className={classes.link}>Logout</Link>
                  </nav>
                </>
              )}
            </>
          )}
        </Toolbar>
      </AppBar>
      {
        state.ready && state.verified &&
        <>
          <Drawer
            open={pageState.drawer}
            onClose={toggleDrawer(false)}
            className={classes.drawer}
            variant={smallWindow === false ? 'permanent' : ''}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <Hidden smDown>
              <div className={classes.toolbar} />
            </Hidden>
            <div
              className={classes.fullList}
              role="presentation"
              onClick={toggleDrawer(false)}
              onKeyDown={toggleDrawer(false)}
            >
              <List>
                {/* {state.opts && state.opts.admin && (
                  <>
                    <LinkItemWrapper to={ROUTER_RESTRICTED_INDEX} text="Admin" />
                    <Divider />
                  </>
                )} */}
                <LinkItemWrapper to={ROUTER_INDEX} text="Dashboard" />
                <Divider />
                <LinkItemWrapper to={ROUTER_API_INDEX} text="API Keys" />
                <LinkItemWrapper to={ROUTER_WEBHOOK_INDEX} text="Webhooks" />
                <Divider />
                <LinkItemWrapper to={ROUTER_REPORT_OUTBOUND} text="Outbound Reports" />
                <LinkItemWrapper to={ROUTER_REPORT_INBOUND} text="Inbound Reports" />
                <LinkItemWrapper to={ROUTER_REPORT_OPTOUT} text="Opt Out Reports" />
                <Divider />
                <LinkItemWrapper to={ROUTER_PAYMENT_INDEX} text="Payments" />
                <LinkItemWrapper to={ROUTER_PROFILE_INDEX} text="Profile" />
                <Divider />
                <LinkItemWrapper to={ROUTER_LOGOUT} text="Logout" />
              </List>
            </div>
          </Drawer>
        </>
      }
    </>
  );
};

export default withWidth()(Header);
