import { createMuiTheme } from "@material-ui/core";

export const primaryColor = '#3d4977';

export default createMuiTheme({
  palette: {
    primary: {
      main: primaryColor,
    },
  },
});
