import React, { useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import LinearProgress from '@material-ui/core/LinearProgress';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';

import Form from './forms/Form';
import LinkWrapper from '../lib/LinkWrapper';
import { UserContext } from '../context/UserContext';

const useStyles = makeStyles(theme => ({
  wrapper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  gridRight: {
    textAlign: 'right',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const AuthForm = (props) => {
  const classes = useStyles();
  const [state] = useContext(UserContext);

  if (!state.ready) {
    return <LinearProgress />
  }

  return (
    <Container maxWidth="xs">
      <div className={classes.wrapper}>
        <Typography component="h1" variant="h5">
          {props.title}
        </Typography>
        <Form onSubmit={props.onSubmit}>
          {props.children}
          {props.ready && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={props.disabled}
            >
              {props.title}
            </Button>
          )}
          <Grid container>
            { props.leftVariantLink && (
              <Grid item xs={6}>
                <Link component={LinkWrapper} to={props.leftVariantLink} variant="body2">
                  {props.leftVariantText}
                </Link>
              </Grid>
            )}
            { props.rightVariantLink && (
            <Grid item xs={props.leftVariantLink ? 6 : 12} className={classes.gridRight}>
              <Link component={LinkWrapper} to={props.rightVariantLink} variant="body2">
                {props.rightVariantText}
              </Link>
            </Grid>
            )}
          </Grid>
        </Form>
      </div>
    </Container>
  );
};

export default AuthForm;
