import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import { Capitalise } from '../../helpers/StringNumber';
import { usePaper } from '../../helpers/Classes';
import NumberFormatWrapper from './NumberFormatWrapper';
import Skeleton from '@material-ui/lab/Skeleton';

const PrimaryItem = ({ ready, primary, title, color, children }) => {
  const classes = usePaper();

  return (
    <Grid item xs={6} sm={3} md={2}>
      { ready ? (<Paper className={classes.paper} style={{ color }}>
        <Typography variant="subtitle2">
          <Capitalise>{title}</Capitalise>
        </Typography>
        <Typography variant={primary ? 'subtitle2' : 'body2' }>
          {
            typeof children === 'string'
            ? children
            : <NumberFormatWrapper>{children}</NumberFormatWrapper>
          }
        </Typography>
      </Paper>) : <Skeleton variant="rect" width="100%" height={100} /> }
    </Grid>
  );
};

PrimaryItem.propTypes = {
  primary: PropTypes.bool,
  title: PropTypes.string.isRequired,
  colour: PropTypes.string,
  ready: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

PrimaryItem.defaultProps = {
  primary: false,
  colour: undefined,
  ready: false,
  children: null,
};

export default PrimaryItem;
