import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import { ROUTER_INDEX, ROUTER_REPORT_OUTBOUND, ROUTER_REPORT_INBOUND, ROUTER_REPORT_OPTOUT, ROUTER_REPORT_INDEX } from '../../../routes/Constants';
import Inbound from './Inbound';
import Outbound from './Outbound';
import OptOut from './OptOut';

const ReportRoutes = () => {
  const [state] = useContext(UserContext);

  if (!state || !state.ready || !state.verified) {
    return <LinearProgress />;
  }

  return (
    <Switch>
      <Route exact path={ROUTER_REPORT_INDEX}>
        <Redirect to={ROUTER_REPORT_OUTBOUND} />
      </Route>
      <Route exact path={ROUTER_REPORT_OUTBOUND} component={Outbound} />
      <Route exact path={ROUTER_REPORT_INBOUND} component={Inbound} />
      <Route exact path={ROUTER_REPORT_OPTOUT} component={OptOut} />
      <Route>
        <Redirect to={ROUTER_INDEX} />
      </Route>
    </Switch>
  );
};

export default ReportRoutes;
