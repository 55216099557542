import React, { useState, useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import {
  GoogleReCaptchaProvider,
  GoogleReCaptcha
} from 'react-google-recaptcha-v3';

import { UserContext } from '../../context/UserContext';
import Input from '../../components/forms/Input';
import AuthForm from '../../components/AuthForm';
import ApiHOC from '../../lib/ApiHOC';
import { ROUTER_LOGIN } from '../../routes/Constants';

const Register = (props) => {
  const pushFlashState = useContext(UserContext)[2];

  useEffect(() => {
    document.title = 'Register';
  });

  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const [processState, setProcessState] = useState({
    form: false,
    page: false,
  });

  const [error, setError] = useState({
    firstName: false,
    lastName: false,
    email: false,
  });

  const handleChange = e => {
    const { id, value } = e.target;

    setForm({ ...form, [id]: value });
    setError({ ...error, [id]: false });
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    setError({});
    setProcessState({ ...processState, form: true });

    const newError = {};

    if (!form.email || !form.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {
      newError.email = true;
    }

    if (!form.firstName) {
      newError.firstName = true;
    }

    if (!form.password || form.password.length < 5) {
      newError.password = true;
    }

    if (!form.passwordConfirm || form.password !== form.passwordConfirm) {
      newError.passwordConfirm = true;
    }

    setError(newError);

    if (Object.keys(newError).length === 0) {
      const body = {
        firstName: form.firstName,
        lastName: form.lastName,
        username: form.email,
        password: form.password,
        recaptcha: form.recaptcha,
      };

      try {
        await props.handleRequest('register', '/auth/register', 'post', {}, body);
      } catch (err) {
        pushFlashState(
          err.message,
          'error',
        );
      }

      localStorage.clear();
    }

    setProcessState({ ...processState, form: false });
  }

  const handleTokenChange = token => {
    const e = {
      target: {
        id: 'recaptcha',
        value: token,
      },
    };

    handleChange(e);
  }

  return (
    <AuthForm
      title="Register"
      disabled={processState.form}
      onSubmit={handleSubmit}
      leftVariantText="Already have an account? Login"
      leftVariantLink={ROUTER_LOGIN}
      ready={true}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Input
            id="firstName"
            label="First Name"
            onChange={handleChange}
            required={true}
            disabled={processState.form}
            error={!!error.firstName}
            helperText="Must enter your first name"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Input
            id="lastName"
            label="Last Name"
            onChange={handleChange}
            disabled={processState.form}
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="email"
            label="Email Address"
            onChange={handleChange}
            required={true}
            disabled={processState.form}
            error={!!error.email}
            helperText="Invalid email address"
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="password"
            label="Password"
            type="password"
            onChange={handleChange}
            required={true}
            disabled={processState.password}
            error={!!error.password}
            helperText="Password is a bit weak, try making it a bit longer"
          />
        </Grid>
        <Grid item xs={12}>
          <Input
            id="passwordConfirm"
            label="Confirm Password"
            type="password"
            onChange={handleChange}
            required={true}
            disabled={processState.passwordConfirm}
            error={!!error.passwordConfirm}
            helperText="Passwords do not match"
          />
        </Grid>
        <Grid item xs={12}>
          <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITEKEY}>
            <GoogleReCaptcha onVerify={handleTokenChange} />
          </GoogleReCaptchaProvider>
        </Grid>
      </Grid>
    </AuthForm>
  );
}

const RegisterFetch = ApiHOC(
  Register,
);

export default RegisterFetch;
