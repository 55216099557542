import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from '../context/UserContext';
import Logout from '../pages/private/Logout';
import Profile from '../pages/private/Profile';
import VerifyEmailAddress from '../pages/public/VerifyEmailAddress';
import ApiRoutes from '../pages/private/Api/Routes';
import ReportRoutes from '../pages/private/Report/Routes';
import PaymentRoutes from '../pages/private/Payment/Routes';
import Webhook from '../pages/private/Webhook';
import Dashboard from '../pages/private/Dashboard';

import RestrictedRoutes from '../pages/restricted/Routes';

import {
  ROUTER_API_INDEX,
  ROUTER_INDEX,
  ROUTER_LOGOUT,
  ROUTER_PAYMENT_INDEX,
  ROUTER_PROFILE_INDEX,
  ROUTER_REPORT_INDEX,
  ROUTER_VERIFY_EMAIL,
  ROUTER_RESTRICTED_INDEX,
  ROUTER_WEBHOOK_INDEX,
} from './Constants';

const SecureRoutes = () => {
  const [state] = useContext(UserContext);

  if (!state || !state.ready || !state.verified) {
    return <LinearProgress />;
  }

  return (
    <Switch>
      <Route exact path={ROUTER_INDEX} component={Dashboard} />
      <Route exact path={ROUTER_PROFILE_INDEX} component={Profile} />
      <Route exact path={ROUTER_WEBHOOK_INDEX} component={Webhook} />
      <Route exact path={ROUTER_LOGOUT} component={Logout} />
      <Route exact path={ROUTER_VERIFY_EMAIL} component={VerifyEmailAddress} />
      <Route path={ROUTER_API_INDEX} component={ApiRoutes} />
      <Route path={ROUTER_REPORT_INDEX} component={ReportRoutes} />
      <Route path={ROUTER_PAYMENT_INDEX} component={PaymentRoutes} />
      <Route path={ROUTER_RESTRICTED_INDEX} component={RestrictedRoutes} />
      <Route>
        <Redirect to={ROUTER_INDEX} />
      </Route>
    </Switch>
  );
};

export default SecureRoutes;
