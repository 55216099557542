import React, { useEffect } from 'react';
import ContainerWrapper from '../../../components/ContainerWrapper';
import Link from '@material-ui/core/Link';
import { ROUTER_PAYMENT_CARD_INDEX, ROUTER_PAYMENT_CREDIT_INDEX, ROUTER_PAYMENT_HISTORY_INDEX } from '../../../routes/Constants';
import LinkWrapper from '../../../lib/LinkWrapper';
import { Switch, Redirect } from 'react-router-dom';

const PaymentIndex = () => {
  useEffect(() => {
    document.title = 'Payments';
  });

  return (
    <>
      <ContainerWrapper ready={false} title="Payments">
        <Link component={LinkWrapper} to={ROUTER_PAYMENT_CARD_INDEX} variant="body2">
          Credit Card Management
        </Link>

        <Link component={LinkWrapper} to={ROUTER_PAYMENT_CREDIT_INDEX} variant="body2">
          Pre Paid Credits
        </Link>

        <Link component={LinkWrapper} to={ROUTER_PAYMENT_HISTORY_INDEX} variant="body2">
          Purchase History
        </Link>
      </ContainerWrapper>
      <Switch>
        <Redirect to={ROUTER_PAYMENT_CREDIT_INDEX} />
      </Switch>
    </>
  );
};

export default PaymentIndex;
