import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../../../context/UserContext';
import CardIndex from './Index';
import CardModify from './Modify';
import CardDelete from './Delete';
import { ROUTER_PAYMENT_CARD_INDEX, ROUTER_PAYMENT_CARD_ADD, ROUTER_INDEX, ROUTER_PAYMENT_CARD_DELETE } from '../../../../routes/Constants';

const CardRoutes = () => {
  const [state] = useContext(UserContext);

  if (!state || !state.ready || !state.verified) {
    return <LinearProgress />;
  }

  return (
    <Switch>
      <Route exact path={ROUTER_PAYMENT_CARD_INDEX} component={CardIndex} />
      <Route exact path={ROUTER_PAYMENT_CARD_ADD} component={CardModify} />
      <Route exact path={ROUTER_PAYMENT_CARD_DELETE} component={CardDelete} />
      <Route>
        <Redirect to={ROUTER_INDEX} />
      </Route>
    </Switch>
  );
};

export default CardRoutes;
