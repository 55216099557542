import React, { useContext } from 'react';
import { UserContext } from '../context/UserContext';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Switch, Route, Redirect } from 'react-router-dom';

import Login from '../pages/public/Login';
import Register from '../pages/public/Register';
import ForgotPassword from '../pages/public/ForgotPassword';
import SecureRoutes from './SecureRoutes';
import VerifyEmailAddress from '../pages/public/VerifyEmailAddress';
import ResetPassword from '../pages/public/ResetPassword';
import Logout from '../pages/private/Logout';
import Page404 from '../pages/public/Page404';
import { ROUTER_LOGOUT, ROUTER_REGISTER, ROUTER_LOGIN, ROUTER_INDEX, ROUTER_FORGOT_PASSWORD, ROUTER_VERIFY_EMAIL, ROUTER_RESET_PASSWORD } from './Constants';

const Routes = () => {
  const [state] = useContext(UserContext);

  if (!state.ready) {
    return <LinearProgress />;
  }

  if (state.verified) {
    return <SecureRoutes />
  }

  return (
    <Switch>
      <Route exact path={ROUTER_INDEX}>
        <Redirect to={ROUTER_LOGIN} />
      </Route>
      <Route exact path={ROUTER_LOGIN} component={Login} />
      <Route exact path={ROUTER_REGISTER} component={Register} />
      <Route exact path={ROUTER_FORGOT_PASSWORD} component={ForgotPassword} />
      <Route exact path={ROUTER_RESET_PASSWORD} component={ResetPassword} />
      <Route exact path={ROUTER_VERIFY_EMAIL} component={VerifyEmailAddress} />
      <Route exact path={ROUTER_LOGOUT} component={Logout} />
      <Route>
        <Page404 />
      </Route>
    </Switch>
  );
};

export default Routes;
