import React, { useState, useEffect } from 'react';
import { ROUTER_INDEX, ROUTER_REPORT_INDEX } from '../../../routes/Constants';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import InputDate from '../../../components/forms/InputDate';
import Input from '../../../components/forms/Input';
import SkeletonInput from '../../../partials/SkeletonInput';
import FormWrapper from '../../../components/forms/FormWrapper';
import InputSelect from '../../../components/forms/InputSelect';
// import querystring from 'querystring';


const Header = (props) => {
  const [inputState, setInputState] = useState({
    search: 'dateRange',
  });
  const [errorState, setErrorState] = useState({});
  const [processState, setProcessState] = useState({});

  useEffect(() => {
    if (!inputState || !inputState.startDate) {
      // const qs = querystring.parse(props.location.search.substr(1));
      // const hasQuery = !!(qs && qs.startDate && qs.finishDate && qs.page);

      let startDate = moment();
      let finishDate = moment();
      let page = 1;

      startDate.subtract(7, 'days');
      startDate.hour(0);
      startDate.minute(0);
      startDate.second(0);

      finishDate.hour(23);
      finishDate.minute(59);
      finishDate.second(59);

      // if (hasQuery) {
      //   const dateFormat = 'YYYY-MM-DD';

      //   const startDateMoment = moment(qs.startDate, dateFormat);
      //   const finishDateMoment = moment(qs.finishDate, dateFormat);

      //   if (
      //     startDateMoment.isValid() &&
      //     finishDateMoment.isValid()
      //   ) {
      //     startDate = startDateMoment;
      //     finishDate = finishDateMoment;
      //     page = qs.page;
      //   }
      // }

      setInputState({
        ...inputState,
        startDate,
        finishDate,
        messageId: null,
        addr: null,
        page,
      });
    }
  }, [inputState, setInputState, props]);

  const handleInputChange = e => {
    const { id, value } = e.target;

    handleChange(id, value);
  }

  const handleChange = (id, value) => {
    setInputState({
      ...inputState,
      [id]: value,
    });

    setErrorState({
      ...errorState,
      [id]: null,
    });
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    await fetchReport();

    /*
    // props.history.push(`/report/outbound?${qs}`);
    */
  }

  const fetchReport = async () => {
    const newProcessState = {
      ...processState,
      form: true,
    }

    setProcessState({
      ...newProcessState
    });

    const { startDate, finishDate, messageId, addr } = inputState;

    const newError = {};

    const dateSearch =  ['dateRange', 'addr', 'summary'].includes(inputState.search);

    if (!moment.isMoment(startDate) && dateSearch) {
      newError.startDate = 'Invalid start date';
    }

    if (!moment.isMoment(finishDate) && dateSearch) {
      newError.finishDate = 'Invalid finish date';
    }

    if (!messageId && inputState.search === 'messageId') {
      newError.messageId = 'Invalid message ID';
    }

    if (!addr && inputState.search === 'addr') {
      newError.addr = `Invalid ${props.addr}`;
    }

    if (!newError.startDate && !newError.finishDate) {
      const duration = moment.duration(finishDate.diff(startDate)).asMonths();

      if (duration < 0) {
        newError.startDate = 'Start date must be before finish date';
      }

      if (duration > 6) {
        newError.finishDate = 'Cannot query more than 6 months at a time';
      }
    }

    setErrorState({
      ...newError
    });

    if (Object.keys(newError).length === 0) {
      const dateFormat = 'YYYY-MM-DDTHH:mm:ss';

      props.onSubmit({
        ...inputState,
        startDate: inputState.startDate.format(dateFormat),
        finishDate: inputState.finishDate.format(dateFormat),
        page: 1,
      });
    }

    setProcessState({});
  }

  const ready = inputState && inputState.startDate ? true : false;

  const searchItems = [{
    label: 'Date Range',
    value: 'dateRange',
  }];

  if (!props.slim) {
    searchItems.push({
      label: 'Message ID',
      value: 'messageId',
    });
  }

  searchItems.push({
    label: props.addr,
    value: 'addr',
  });

  searchItems.push({
    label: 'Summary',
    value: 'summary',
  });

  return (
    <FormWrapper
      onSubmit={handleSubmit}
      disabled={processState.form}
      action="Run Report"
      ready={ready}
      title="Reports"
      subtitle={props.direction}
      breadcrumbs={[
        [
          'Dashboard',
          ROUTER_INDEX,
        ],
        [
          'Reports',
          ROUTER_REPORT_INDEX,
        ],
        [
          props.direction,
        ],
      ]}
    >
      <Grid container spacing={3}>
        <Grid item md={3} xs={12}>
          { ready ? <InputSelect
              id="search"
              label="Search Type"
              value={inputState.search}
              items={searchItems}
              handleChange={value => handleChange('search', value)}
              disabled={processState.form}
            /> : <SkeletonInput /> }
        </Grid>
        { ['dateRange', 'addr', 'summary'].includes(inputState.search) && (
          <>
            <Grid item md={3} xs={12}>
              { ready ? <InputDate
                id="startDate"
                label="Start Date"
                value={inputState.startDate}
                disabled={processState.form}
                onChange={handleChange}
                error={!!errorState.startDate}
                helperText={errorState.startDate}
                /> : <SkeletonInput /> }
            </Grid>
            <Grid item md={3} xs={12}>
              { ready ? <InputDate
                id="finishDate"
                label="Finish Date"
                value={inputState.finishDate}
                disabled={processState.form}
                onChange={handleChange}
                error={!!errorState.finishDate}
                helperText={errorState.finishDate}
                /> : <SkeletonInput /> }
            </Grid>
          </>
        )}
        { inputState.search === 'messageId' && (
          <Grid item md={9} xs={12}>
            { ready ? <Input
              id="messageId"
              label="Message ID"
              value={inputState.messageId}
              disabled={processState.form}
              required={true}
              onChange={handleInputChange}
              error={!!errorState.messageId}
              helperText={errorState.messageId}
              /> : <SkeletonInput /> }
          </Grid>
        )}
        { inputState.search === 'addr' && (
          <Grid item md={3} xs={12}>
            { ready ? <Input
              id="addr"
              label={props.addr}
              value={inputState.addr}
              disabled={processState.form}
              onChange={handleInputChange}
              error={!!errorState.addr}
              helperText={errorState.addr}
              /> : <SkeletonInput /> }
          </Grid>
        )}
      </Grid>
    </FormWrapper>
  );
};

export default Header;
