import React, { useEffect, useState } from 'react';
import ApiHOC from '../../lib/ApiHOC';
import InputSelect from './InputSelect';
import SkeletonInput from '../../partials/SkeletonInput';
import { Link } from '@material-ui/core';
import LinkWrapper from '../../lib/LinkWrapper';
import { ROUTER_PAYMENT_CARD_INDEX } from '../../routes/Constants';

const CreditCardSelect = (props) => {
  const [state, setState] = useState({
    ready: false,
  });

  const [inputState, setInputState] = useState({
    creditCard: null,
  });

  useEffect(() => {
    if (!state.ready && props.data) {
      setState({
        ...state,
        ...props.data,
        ready: true,
      });

      if (props.data && props.data.items && props.data.items.length > 0) {
        const creditCard = props.data.items[0].id;

        setInputState({
          ...inputState,
          creditCard,
        });

        props.handleChange(creditCard);
      }
    }
  }, [props, state, setState, inputState, setInputState]);

  const handleChange = value => {
    setInputState({
      ...inputState,
      creditCard: value,
    });

    props.handleChange(value);
  }

  if (state.ready && (
    !state.items ||
    state.items.length === 0)
  ) {
    return (
      <Link component={LinkWrapper} to={ROUTER_PAYMENT_CARD_INDEX} variant="body2">
        Add a credit card to continue with purchase.
      </Link>
    );
  }

  return (
    state.ready ? <InputSelect
    id="creditCard"
    label="Credit Card *"
    value={inputState.creditCard}
    items={state.items.map(item => ({ label: `${item.name} | ${item.pan} (${item.expiryMonth.toString().padStart(2, '0')}/${item.expiryYear.toString().padStart(4, '20')})`, value: item.id }))}
    handleChange={v => handleChange(v)}
    disabled={props.disabled}
  /> : <SkeletonInput />
  );
};

const CreditCardSelectFetch = ApiHOC(
  CreditCardSelect,
  { uri: '/payment/cards'  }
);

export default CreditCardSelectFetch;
