import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { ROUTER_API_INDEX, ROUTER_INDEX } from '../../../routes/Constants';
import { UserContext } from '../../../context/UserContext';
import ApiHOC from '../../../lib/ApiHOC';
import DialogWrapper from '../../../components/DialogWrapper';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormWrapper from '../../../components/forms/FormWrapper';
import Grid from '@material-ui/core/Grid';
import Input from '../../../components/forms/Input';
import InputSelect from '../../../components/forms/InputSelect';
import Key from './Key';
import SkeletonInput from '../../../partials/SkeletonInput';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';

const useWarningStyles = makeStyles(theme => ({
  typography: {
    marginBottom: theme.spacing(2),
  },
  formControl: {
    width: '100%',
  },
}));

const WarningText = ({ children }) => {
  const classes = useWarningStyles();

  return (
    <Typography color="secondary" variant="subtitle1" className={classes.typography} gutterBottom>
      {children}
    </Typography>
  )
};

const ApiModify = (props) => {
  const history = useHistory();
  const { token } = useParams();
  const edit = token ? true : false;

  const userContexts = useContext(UserContext);
  const state = userContexts[0];
  const pushFlashState = userContexts[2];

  const [pageState, setPageState] = useState({
    ready: false,
    dialog: false,
    keys: {},
  });
  const [inputState, setInputState] = useState({
    status: true,
    permissions: {
      smsSend: true,
    },
  });
  const [errorState, setErrorState] = useState({});
  const [processState, setProcessState] = useState({});

  useEffect(() => {
    document.title = edit ? 'Modify API Key' : 'Create API Key';

    if (!pageState.ready && props.data && !pageState.init) {
      setPageState({
        ...pageState,
        init: true,
      });

      const setFinalPageState = () => setPageState({
          ...pageState,
          ready: true,
          init: true,
        });

      if (edit) {
        const fetchModify = async () => props.handleRequest(null, `/api/${token}`, 'get');

        fetchModify()
          .then(resp => {
            setInputState({
              ...resp
            });

            setFinalPageState();
          })
          .catch(() => {
            pushFlashState(
              'Unable to find API Key',
              'error',
            );

            return history.push(ROUTER_API_INDEX);
          });
      } else {
        setFinalPageState();
      }
    }
  }, [
    pageState,
    setPageState,
    state,
    edit,
    token,
    props,
    setInputState,
    pushFlashState,
    history,
  ]);

  const handleChange = e => {
    const { id, value } = e.target;

    setInputState({
      ...inputState,
      [id]: value,
    });

    setErrorState({
      ...errorState,
      [id]: null,
    });
  };

  const handleToggleChange  = (group, id) => event => {
    const inputGroup = inputState[group];
    const { checked } = event.target;

    if (inputGroup[id] !== checked) {
      setInputState({
        ...inputState,
        [group]: {
          ...inputGroup,
          [id]: checked,
        },
      });
    }
  }

  const handleDialogToggle = () => {
    setPageState({
      ...pageState,
      dialog: false,
      keys: {},
    });


    if (!pageState.delete) {
      history.push(ROUTER_API_INDEX);
    }
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    setErrorState({});
    setProcessState({
      ...processState,
      form: true,
    });

    const newError = {};

    if (!inputState.name) {
      newError.name = true;
    }

    setErrorState(newError);

    if (Object.keys(newError).length === 0) {
      const body = {
        name: inputState.name,
        permissions: inputState.permissions,
        status: inputState.status,
      };

      const uri = edit ? `/api/${token}` : '/api';
      const method = edit ? 'patch' : 'post';

      await props.handleRequest(null, uri, method, {}, body)
        .then(resp => {
          const keys = edit ? {} : { ...resp };

          setPageState({
            ...pageState,
            keys,
            dialog: !edit,
          });

          pushFlashState(
            edit ? 'API Key updated' : 'API Key created',
            'success',
          );
        })
        .catch(() => {
          pushFlashState(
            edit ? 'Unable to update API Key' : 'Unable to create API Key',
            'error',
          );
        });
    }

    setProcessState({
      ...processState,
      form: false,
    });
  }

  const handleDelete = async () => {
    if (edit) {
      setProcessState({
        ...processState,
        form: true,
      });

      await props.handleRequest(null, `/api/${token}`, 'delete', {})
        .then(() => {
          pushFlashState(
            'API Key deleted',
            'success',
          );

          history.push(ROUTER_API_INDEX);
        })
        .catch(() => {
          pushFlashState(
            'Unable to delete API Key',
            'error',
          );

          handleDialogToggle();

          setProcessState({
            ...processState,
            form: false,
          })
        });
    }
  }

  const handleDeleteToggle = async () => {
    setPageState({
      ...pageState,
      dialog: true,
      delete: true,
    });
  }

  const ready = pageState.ready ? true : false;

  return (
    <>
      <FormWrapper
        onSubmit={handleSubmit}
        disabled={processState.form}
        action={edit ? 'Update' : 'Create'}
        ready={ready}
        title={`${edit ? 'Modify' : 'Created'} API Key`}
        variant={edit ? [{
          handleClick: handleDeleteToggle,
          text: 'Delete',
        }] : []}
        breadcrumbs={[
          [
            'Dashboard',
            ROUTER_INDEX,
          ],
          [
            'API Keys',
            ROUTER_API_INDEX,
          ],
          [
            edit ? 'Modify API Key' : 'Create API Key',
          ]
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {
              ready ? <Input
                  id="name"
                  label="Identifying name"
                  autoFocus={true}
                  onChange={handleChange}
                  value={inputState.name ? inputState.name : undefined}
                  required={true}
                  disabled={processState.form}
                  error={!!errorState.name}
                  helperText="Must enter an identifying name"
                /> :
                <SkeletonInput />
            }
          </Grid>
          <Grid item xs={12}>
            {
              ready ? <InputSelect
                id="status"
                label="Status *"
                value={inputState.status ? true : false}
                items={[{ label: 'Enabled', value: true }, { label: 'Disabled', value: false }]}
                handleChange={status => setInputState({ ...inputState, status })}
                disabled={processState.form}
              /> :
              <SkeletonInput />
            }
          </Grid>
          <Grid item xs={12}>
            {
              ready ? <FormControl component="fieldset">
                <FormLabel component="legend">Permissions</FormLabel>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={inputState.permissions.smsSend}
                        onChange={handleToggleChange('permissions', 'smsSend')}
                        disabled={processState.form}
                        value={true}
                        color="primary"
                      />
                    }
                    label="SMS Sending"
                  />
                </FormGroup>
              </FormControl> :
              <SkeletonInput />
            }
          </Grid>
        </Grid>
      </FormWrapper>
      <DialogWrapper
        open={pageState.dialog}
        title={pageState.delete ? 'Delete API Key' : 'API Keys'}
        handleToggle={handleDialogToggle}
        close={pageState.delete ? 'Cancel' : 'Close' }
        primaryAction={pageState.delete ? {
          label: 'Delete',
          class: 'secondary',
          handleClick: handleDelete,
          processing: processState.form,
        } : undefined }
      >
        { pageState.delete && (
          <>
            <WarningText>
              Are you sure you want to delete this API Key?
            </WarningText>
            <WarningText>
              This action cannot be reversed and is permanent.
            </WarningText>
          </>
        )}
        { !pageState.delete && (
          <>
            <WarningText>
              These API key can only be shown once.
            </WarningText>
            <WarningText>
              Store them in a safe place as they cannot be recovered.
            </WarningText>
            <Key
              name="Access key"
              value={pageState.keys.token}
            />
            <Key
              name="Secret token"
              value={pageState.keys.secret}
            />
            <Key
              name="UUID"
              value={pageState.keys.uuid}
            />
          </>
        )}
      </DialogWrapper>
    </>
  );
};

const ApiModifyFetch = ApiHOC(
  ApiModify,
);

export default ApiModifyFetch;
