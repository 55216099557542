import React, { useState, useContext, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Input from '../../components/forms/Input';
import SkeletonInput from '../../partials/SkeletonInput';
import { FlashContext } from '../../context/FlashContext';
import FormWrapper from '../../components/forms/FormWrapper';
import PasswordChange from '../../components/forms/PasswordChange';
import ApiHOC from '../../lib/ApiHOC';

const Profile = (props) => {
  const [state, setState] = useState({});
  const [pushFlashState] = useContext(FlashContext);

  const [inputState, setInputState] = useState({});
  const [processState, setProcessState] = useState({});
  const [errorState, setErrorState] = useState({});

  useEffect(() => {
    document.title = 'Profile';

    if (!state.ready && props.data) {
      setInputState({
        ...inputState,
        ...props.data
      });

      setState({
        ...state,
        ready: true,
      });
    }
  }, [props, state, setState, inputState, setInputState]);

  const handleChange = e => {
    const { id, value } = e.target;

    setInputState({
      ...inputState,
      [id]: value,
    });

    setErrorState({
      ...errorState,
      [id]: null,
    });
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    setProcessState({
      ...processState,
      form: true,
    });

    const newError = {};

    if (!inputState.firstName) {
      newError.firstName = true;
    }

    setErrorState(newError);

    if (Object.keys(newError).length === 0) {
      const body = {
        firstName: inputState.firstName,
        lastName: inputState.lastName ? inputState.lastName : null,
      };

      await props.handleRequest(null, '/profile', 'patch', {}, body)
        .then(() => {
          pushFlashState(
            'Profile updated',
            'success',
          );
        })
        .catch(() => {
          pushFlashState(
            'Unable to update profile details',
            'error',
          );
        });
    }

    setProcessState({
      ...processState,
      form: false,
    });
  }

  const handleDialog = (e = null) => {
    if (e) {
      e.preventDefault();
    }

    setState({ ...state, open: !state.open });
  }

  const ready = state.ready ? true : false;

  return (
    <>
      <FormWrapper
        onSubmit={handleSubmit}
        disabled={processState.form}
        action="Update"
        ready={ready}
        title="Profile"
        variant={[{
          handleClick: handleDialog,
          text: 'Change password',
        }]}
      >
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            {
              ready ? <Input
                  id="firstName"
                  label="First Name"
                  autoFocus={true}
                  value={props.data.firstName}
                  onChange={handleChange}
                  required={true}
                  disabled={processState.form}
                  error={!!errorState.firstName}
                  helperText="Must enter your first name"
                /> :
                <SkeletonInput />
            }
          </Grid>
          <Grid item xs={12} sm={6}>
            {
              ready ? <Input
                  id="lastName"
                  label="Last Name"
                  value={props.data.lastName}
                  onChange={handleChange}
                  disabled={processState.form}
                /> :
                <SkeletonInput />
            }
          </Grid>
          <Grid item xs={12}>
          {
            ready ? <Input
                id="email"
                label="Email Address"
                value={props.data.email}
                onChange={handleChange}
                required={true}
                disabled={true}
                error={!!errorState.email}
                helperText="Invalid email address"
              /> :
              <SkeletonInput />
            }
          </Grid>
        </Grid>
      </FormWrapper>

      <PasswordChange
        open={state.open ? state.open : false}
        handleToggle={handleDialog}
        currentPassword={true}
      />
    </>
  );
};

const ProfileFetch = ApiHOC(
  Profile,
  { uri: '/profile'  }
);

export default ProfileFetch;
