import React from 'react';
import PropTypes from 'prop-types';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

const PrettifyAddress = props => {
  const { children, strict } = props;

  if (children && children.toString().match(/^(\+)?([0-9]+)$/)) {
    const phoneUtil = PhoneNumberUtil.getInstance();
    const addrNumber = phoneUtil.parseAndKeepRawInput(`+${children.replace(/\+/, '')}`);

    return (
      <>
        {phoneUtil.format(addrNumber, PhoneNumberFormat.INTERNATIONAL)}
      </>
    );
  }

  if (!strict) {
    return <>{children}</>
  }

  return <></>
}

export default PrettifyAddress;


PrettifyAddress.propTypes = {
  children: PropTypes.node.isRequired,
  strict: PropTypes.bool,
};

PrettifyAddress.defaultProps = {
  strict: true,
};
