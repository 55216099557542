import React, { useContext } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext } from '../../../context/UserContext';
import ApiIndex from './Index';
import ApiModify from './Modify'
import { ROUTER_API_INDEX, ROUTER_API_CREATE, ROUTER_INDEX, ROUTER_API_EDIT } from '../../../routes/Constants';

const ApiRoutes = () => {
  const [state] = useContext(UserContext);

  if (!state || !state.ready || !state.verified) {
    return <LinearProgress />;
  }

  return (
    <Switch>
      <Route exact path={ROUTER_API_INDEX} component={ApiIndex} />
      <Route exact path={ROUTER_API_CREATE} component={ApiModify} />
      <Route exact path={ROUTER_API_EDIT} component={ApiModify} />
      <Route>
        <Redirect to={ROUTER_INDEX} />
      </Route>
    </Switch>
  );
};

export default ApiRoutes;
