import React, { useEffect, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import ApiHOC from '../../../../lib/ApiHOC';
import ContainerWrapper from '../../../../components/ContainerWrapper';
import { ROUTER_PAYMENT_INDEX, ROUTER_PAYMENT_CARD_ADD, ROUTER_INDEX, ROUTER_PAYMENT_CARD_INDEX } from '../../../../routes/Constants';
import TableWrapper from '../../../../components/TableWrapper';
import { FlashContext } from '../../../../context/FlashContext';
import qs from 'query-string';

const CardIndex = (props) => {
  const history = useHistory();
  const [pushFlashState] = useContext(FlashContext);
  const [state, setState] = useState({
    ready: false,
  });

  useEffect(() => {
    document.title = 'Credit Cards';

    const query = qs.parse(props.location.search);

    if (query && query.state) {
      if (query.state === 'success') {
        pushFlashState('Credit card saved', 'success');
      } else if (query.state) {
        if (query.message) {
          pushFlashState(query.message, 'error');
        } else {
          pushFlashState('Unexpected error', 'error');
        }
      }

      history.replace(ROUTER_PAYMENT_CARD_INDEX);
    } else if (!state.ready && props.data) {
      setState({
        ...state,
        ...props.data,
        ready: true,
      });
    }
  }, [props, state, setState, pushFlashState, history]);

  const { ready } = state;

  const handlePagination = async pageNumber => {
    const resp = await props.handleRequest('data', '/payment/cards', 'get', { page: pageNumber });

    await setState({
      ...state,
      ...resp,
    });
  }

  return (
    <ContainerWrapper
      ready={true}
      title="Credit Cards"
      button={{ text: 'Add Credit Card', link: ROUTER_PAYMENT_CARD_ADD }}
      breadcrumbs={[
        [
          'Dashboard',
          ROUTER_INDEX,
        ],
        [
          'Payments',
          ROUTER_PAYMENT_INDEX,
        ],
        [
          'Credit Cards',
          ROUTER_PAYMENT_CARD_INDEX,
        ]
      ]}
    >
    <TableWrapper
      headers={['Name', 'Card Number', 'Expiry', 'Created', 'Last Used']}
      ready={ready}
      rows={ready ? state.items.map(item => [item.name, item.pan, `${item.expiryMonth.toString().padStart(2, '0')}/${item.expiryYear.toString().padStart(4, '20')}`, item.created, item.lastUsed]) : []}
      hasNext={ready && state.hasNext}
      pageSize={ready ? state.pageSize : 0}
      pageNumber={ready ? state.pageNumber : 0}
      pagination={handlePagination}
    />
    </ContainerWrapper>
  );
};

const CardIndexFetch = ApiHOC(
  CardIndex,
  { uri: '/payment/cards'  }
);

export default CardIndexFetch;
