import React, { useContext, useState, useEffect } from 'react';
import FormWrapper from '../../../components/forms/FormWrapper';
import { useHistory } from 'react-router-dom';
import { ROUTER_INDEX, ROUTER_PAYMENT_INDEX, ROUTER_PAYMENT_CREDIT_INDEX, ROUTER_PAYMENT_HISTORY_INDEX, ROUTER_PAYMENT_CARD_INDEX } from '../../../routes/Constants';
import Input from '../../../components/forms/Input';
import SkeletonInput from '../../../partials/SkeletonInput';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import { FlashContext } from '../../../context/FlashContext';
import CreditCardSelect from '../../../components/forms/CreditCardSelect';
import ApiHOC from '../../../lib/ApiHOC';

const PaymentCredits = (props) => {
  const history = useHistory();
  const [pushFlashState] = useContext(FlashContext);

  const [state, setState] = useState({});
  const [inputState, setInputState] = useState({
    purchaseValue: 50
  });
  const [processState, setProcessState] = useState({});
  const [errorState, setErrorState] = useState({});

  useEffect(() => {
    document.title = 'Pre Paid Credits';

    if (!state.ready) {
      setState({
        ...state,
        ready: true,
      });
    }

    // if (!state.ready && props.data) {
    //   setInputState({
    //     ...inputState,
    //     ...props.data
    //   });

    //   setState({
    //     ...state,
    //     ready: true,
    //   });
    // }
  }, [state, setState]); //, [props, state, setState, inputState, setInputState]);

  const handleChange = e => {
    const { id, value } = e.target;

    setInputState({
      ...inputState,
      [id]: value,
    });

    setErrorState({
      ...errorState,
      [id]: null,
    });
  }

  const handlePaymentHistory = () => {
    history.push(ROUTER_PAYMENT_HISTORY_INDEX);
  }

  const handleCreditCardManagement = () => {
    history.push(ROUTER_PAYMENT_CARD_INDEX);
  }

  const handleSubmit = async event => {
    if (event) {
      event.preventDefault();
    }

    setProcessState({
      ...processState,
      form: true,
    });

    const newErrorState = {};

    const { creditCard, purchaseValue, cardCvv } = inputState;

    if (!creditCard) {
      newErrorState.creditCard = 'Must select a credit card.';
    }

    if (!purchaseValue || !purchaseValue.toString().match(/^([0-9]+)$/)) {
      newErrorState.purchaseValue = 'Invalid purchase amount, must be a full dollar amount.';
    }

    if (purchaseValue.toString().match(/^([0-9]+)$/) && parseInt(purchaseValue) < 15) {
      newErrorState.purchaseValue = 'Purchase amount must be greater than or equal to $15 AUD';
    }

    if (!cardCvv || !cardCvv.toString().match(/^([0-9]{3})$/)) {
      newErrorState.cardCvv = 'Invalid CVV';
    }

    setErrorState({
      ...newErrorState,
    });

    if (Object.keys(newErrorState).length === 0) {
      const { creditCard, cardCvv, purchaseValue } = inputState;

      const resp = await props.handleRequest(null, '/payment/credits', 'post', {}, {
        cardId: creditCard,
        amount: purchaseValue,
        cvv: cardCvv.toString(),
      })
        .then(() => {
          pushFlashState('Purchase pending, it will be processed shortly.');

          return true;
        })
        .catch(err => {
          if (err.message.match(/CVV/)) {
            setErrorState({
              ...errorState,
              cardCvv: err.message,
            });

            pushFlashState(
              err.message,
              'error',
            );
          } else {
            pushFlashState(
              'Unable to complete purchase',
              'error',
            );
          }
        });

      if (resp) {
        handlePaymentHistory();

        return;
      }
    }

    setProcessState({
      ...processState,
      form: false,
    });
  }

  const disabledForm = processState.form || !inputState.creditCard;

  return (
    <FormWrapper
        onSubmit={handleSubmit}
        disabled={disabledForm}
        action="Purchase"
        ready={state.ready}
        title="Pre Paid Credits"
        variant={[{
          handleClick: handlePaymentHistory,
          text: 'Payment History',
        }, {
          handleClick: handleCreditCardManagement,
          text: 'Credit Card Management',
        }]}
        breadcrumbs={[
          [
            'Dashboard',
            ROUTER_INDEX,
          ],
          [
            'Payments',
            ROUTER_PAYMENT_INDEX,
          ],
          [
            'Pre Paid Credits',
            ROUTER_PAYMENT_CREDIT_INDEX,
          ]
        ]}
      >
        <Grid container spacing={3}>
          <Grid item xs={8}>
            <CreditCardSelect
              handleChange={value => handleChange({ target: { id: 'creditCard', value }})}
              disabled={disabledForm}
            />
          </Grid>
          <Grid item xs={4}>
          {
              state.ready ? <Input
                label="Card CVV *"
                id="cardCvv"
                value={inputState.cardCvv}
                onChange={handleChange}
                disabled={disabledForm}
                error={!!errorState.cardCvv}
                helperText={errorState.cardCvv}
              /> :
                <SkeletonInput />
            }
          </Grid>
          <Grid item xs={12}>
            {
              state.ready ? <Input
                label="Purchase Amount *"
                id="purchaseValue"
                value={inputState.purchaseValue}
                onChange={handleChange}
                disabled={disabledForm}
                InputProps={{
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  endAdornment: <InputAdornment position="end">AUD</InputAdornment>,
                }}
                error={!!errorState.purchaseValue}
                helperText={errorState.purchaseValue}
              /> :
                <SkeletonInput />
            }
          </Grid>
        </Grid>
      </FormWrapper>
  );
};

const PaymentCreditsFetch = ApiHOC(
  PaymentCredits,
);

export default PaymentCreditsFetch;
