// Default Child Paths
const CREATE_DEFAULT = 'create';

// Private Routes

// API Keys
export const ROUTER_API_INDEX = '/api';
export const ROUTER_API_CREATE = `${ROUTER_API_INDEX}/${CREATE_DEFAULT}`;
export const ROUTER_API_EDIT = `${ROUTER_API_INDEX}/edit/:token`;

// Webhook
export const ROUTER_WEBHOOK_INDEX = '/webhook';

// Reports
export const ROUTER_REPORT_INDEX = '/report';
export const ROUTER_REPORT_OUTBOUND = `${ROUTER_REPORT_INDEX}/outbound`;
export const ROUTER_REPORT_INBOUND = `${ROUTER_REPORT_INDEX}/inbound`;
export const ROUTER_REPORT_OPTOUT = `${ROUTER_REPORT_INDEX}/optout`;
export const ROUTER_REPORT_OUTBOUND_MESSAGE_ID = `${ROUTER_REPORT_INDEX}/outbound/:messageId`;

// Payment
export const ROUTER_PAYMENT_INDEX = '/payment';

// Payment / Credit Cards
export const ROUTER_PAYMENT_CARD_INDEX = `${ROUTER_PAYMENT_INDEX}/credit-card`;
export const ROUTER_PAYMENT_CARD_ADD = `${ROUTER_PAYMENT_CARD_INDEX}/${CREATE_DEFAULT}`;
export const ROUTER_PAYMENT_CARD_DELETE = `${ROUTER_PAYMENT_CARD_INDEX}/delete/:cardId`;

// Payment / Credits
export const ROUTER_PAYMENT_CREDIT_INDEX = `${ROUTER_PAYMENT_INDEX}/credit`;

// Payment / History
export const ROUTER_PAYMENT_HISTORY_INDEX = `${ROUTER_PAYMENT_INDEX}/history`;

// Profile
export const ROUTER_PROFILE_INDEX = '/profile';

// Public Routes
export const ROUTER_INDEX = '/';

export const ROUTER_REGISTER = '/register';
export const ROUTER_LOGIN = '/login';
export const ROUTER_LOGOUT = '/logout';

export const ROUTER_FORGOT_PASSWORD = '/forgot-password';
export const ROUTER_RESET_PASSWORD = '/reset-password/:usernameHash/:token';
export const ROUTER_VERIFY_EMAIL = '/verify-email/:usernameHash/:token';


// Restricted Routes
export const ROUTER_RESTRICTED_INDEX = '/restricted';

// Payment
export const ROUTER_RESTRICTED_PAYMENT_INDEX = `${ROUTER_RESTRICTED_INDEX}/payment`;
