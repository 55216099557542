import React, { useContext } from 'react';
import { UserContext } from '../../../context/UserContext';
import { LinearProgress } from '@material-ui/core';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ROUTER_INDEX, ROUTER_RESTRICTED_PAYMENT_INDEX } from '../../../routes/Constants';
import PaymentIndex from './Index';
import PaymentItem from './Item';

const Routes = () => {
  const [state] = useContext(UserContext);

  if (!state.ready) {
    return <LinearProgress />
  }

  return (
    <Switch>
      <Route exact path={ROUTER_RESTRICTED_PAYMENT_INDEX} component={PaymentIndex} />
      <Route exact path={`${ROUTER_RESTRICTED_PAYMENT_INDEX}/:paymentId`} component={PaymentItem} />
      <Route>
        <Redirect to={ROUTER_INDEX} />
      </Route>
    </Switch>
  );
}

export default Routes;
